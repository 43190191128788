import React from 'react';
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RevenueChart from "../RevenueThisYear/RevenueChart";

const OnlineSignupsFilled = ({ value, data }) => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick
            title={<Typography fontWeight={"500"} variant={"h3"} color={"common.white"}>{value || 0}</Typography>}
            subheader={
                <Typography
                    variant={"h6"}
                    color={"common.white"}
                    mb={0}>{t('Chamados Resolvidos')}</Typography>
            }
         
            reverse
            sx={{color: "common.white", borderTop: "4px solid #3BD2A2"}}
            bgColor={["#a3ead3", "#3bd2a2"]}
            wrapperSx={{pb: 0}}
        >
            <RevenueChart data={data} shadowColor="#28a745" />
        </JumboCardQuick>
    );
};

export default OnlineSignupsFilled;
