import Div from "@jumbo/shared/Div/Div";
import { Alert, Autocomplete, Avatar, Backdrop, Button, CardHeader, CircularProgress, Fade, FormControl, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../services/config";
import TransferList from "./TransferList";
import { useSelector } from "react-redux";

const ModalRevogarPermissao = ({ open, setOpen }) => {
    const {auth} = useSelector(state => state);

    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchingTecs, setSearchingTecs] = useState(false);
    const [users, setUsers] = useState([]);
    const [adms, setAdms] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);

    const [userSelected, setUserSelected] = useState(null);
    const [tecs, setTecs] = useState([]);

    const [tecsIds, setTecsIds] = useState([]);
    const [success, setSuccess] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: 5,
        minWidth: "80%",
        maxHeight: "80%",
        overflowY: "scroll",
        boxShadow: 24,
        p: 5
    };

    async function fetchUsuáriosListagem(s) {
        if(s.length <= 1) return;

        setSearching(true);
        try {
            const { data } = await api.get("api/v2/dashboard/listagem/usuarios", { params: { s } });

            setAdms(data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearching(false);
    }

    async function fetchTecnicosListagem(user) {
        setSearchingTecs(true);
        try {
            setUserSelected(user);
            const { data } = await api.get("api/v2/dashboard/permissao/listagem/tecnicos", { params: { s: "", auth_id: user.id } });

            setTecs(data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearchingTecs(false);
    }

    async function revokePermissions() {
        setLoading(true);
        
        try {
            const {data} = await api.post("api/v2/revogar-permissao-historico-tecnico", { user_id: userSelected.id, tecs_id: tecsIds });

            console.log("RESPONDE > > ", data);
            
            setUserSelected(null);
            setTecs([]);
            setTecsIds([]);
            setAdms([]);
            setSuccess(true);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setLoading(false);
    }

    useEffect(() => {
        setSuccess(false);
    }, [open]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Div sx={style}>
                    {
                        success && (
                            <Alert severity="success">Permissões revogadas com sucesso!</Alert>
                        )
                    }

                    <Stack direction="row" sx={{ mb: 2 }}>
                        <Div sx={{ display: "flex", flex: 1, flexDirection: "column", mr: 2 }}>
                            <CardHeader title="Selecione o Usuário" />

                            <Stack sx={{ mb: 2 }}>
                                <Div>
                                    <FormControl sx={{width: "100%"}}>
                                        <TextField
                                            label="Buscar Usuário..."
                                            onChange={(e) => {
                                                fetchUsuáriosListagem(e.target.value);
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Div>
                            </Stack>

                            <Stack>
                                {
                                    searching ? (
                                        <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </Div>
                                    ) : (
                                        <Div>
                                            {
                                                adms.map(user => (
                                                    <Stack
                                                        sx={{    
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            mb: 2,
                                                            bgcolor: (userSelected && userSelected.id == user.id) ? "#008598" : "#f5f5f5",
                                                            padding: 2,
                                                            borderRadius: 5,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => fetchTecnicosListagem(user)}
                                                    >
                                                        <Avatar
                                                            alt={user.name}
                                                            src={`https://storage.googleapis.com/uteky/images/tecnicos/${user.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                                                            sx={{width: 50, height: 50, mr: 2}}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                color: (userSelected && userSelected.id == user.id) ? "#FFF" : "#040404"
                                                            }}    
                                                        >
                                                            {user.name}
                                                        </Typography>
                                                    </Stack>
                                                ))
                                            }
                                        </Div>
                                    )
                                }
                            </Stack>
                        </Div>

                        <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <CardHeader title="Listagem de técnicos" />
                            
                            {
                                searchingTecs ? (
                                    <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <CircularProgress/>
                                    </Div>
                                ) : (
                                    <Div>
                                        {
                                            tecs.length == 0 ?
                                                <Typography>{userSelected ? "Esse usuário não possui permissão para acessar esse serviço." : "Selecione um usuário para acessar suas permissões."}</Typography>
                                            : tecs.map(user => (
                                                <Stack
                                                    sx={{    
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        mb: 2,
                                                        bgcolor: tecsIds.includes(user.id) ? "#008598" : "#f5f5f5",
                                                        padding: 2,
                                                        borderRadius: 5,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        setTecsIds(prev => [...prev, user.id])
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={user.name}
                                                        src={`https://storage.googleapis.com/uteky/images/tecnicos/${user.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                                                        sx={{width: 50, height: 50, mr: 2}}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            color: tecsIds.includes(user.id) ? "#FFF" : "#040404"
                                                        }}    
                                                    >
                                                        {user.name}
                                                    </Typography>
                                                </Stack>
                                            ))
                                        }
                                    </Div>
                                )
                            }
                        </Div>
                    </Stack>

                    {
                        tecsIds.length > 0 && (
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: "100%" }}
                                onClick={revokePermissions}
                                disabled={loading}
                            >
                                {
                                    loading ? <CircularProgress size={25} sx={{ color: "#FFF" }} /> : "Revogar Permissão"
                                }
                            </Button>
                        )
                    }
                </Div>
            </Fade>
        </Modal>
    );
}

export default ModalRevogarPermissao;