import React from 'react';
import {Line, LineChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import Div from "@jumbo/shared/Div";
import { useEffect } from 'react';

//todo: add ResponsiveContainer and fix the page reload ResponsiveContainer width issue
const RevenueChart = ({color, shadowColor, data, dataKey}) => {
    return (
        <ResponsiveContainer height={80}>
            <LineChart data={data} className={"mx-auto"}>
                <defs>
                    <filter id="shadowRevenue" height="200%">
                        <feDropShadow
                            dx="0" dy="5" stdDeviation="8"
                            floodColor={shadowColor}
                        />
                    </filter>
                </defs>
                <Tooltip
                    labelStyle={{color: 'black'}}
                    cursor={false}
                    content={({active, label, payload}) => {
                        return active ? (
                            <Div sx={{color: "common.white"}}>
                                {payload.map((row, index) => (
                                    <div key={index}>{`${label}: ${row.value} chamados`}</div>
                                ))}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: color ? color : 'rgba(0,0,0,.8)',
                        padding: '5px 8px',
                        borderRadius: 4,
                        overflow: 'hidden',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                />
                <XAxis dataKey="hKey" hide/>
                <Line dataKey={dataKey || "resolvidos"} filter={"url(#shadowRevenue)"} type="monotone" dot={null} strokeWidth={3}
                      stackId="2" stroke={color ? color : "#fff"}/>
            </LineChart>
        </ResponsiveContainer>
    );
};
/* Todo color, shadowColor prop define */
export default RevenueChart;
