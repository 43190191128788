import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

const DashboardSkeleton = () => {
    return (        
        <Stack id="stack-usuario-skeleton" spacing={4} marginTop={1}>
            <Skeleton variant="rectangular" style={{ height: "250px", borderRadius: 10 }} />

            <Stack direction="row" spacing={4}>
                <Skeleton variant="rectangular" style={{ height: "350px", flex: 1, borderRadius: 10 }} />
                <Skeleton variant="rectangular" style={{ height: "350px", flex: 1, borderRadius: 10 }} />
            </Stack>

            <Stack direction="row" spacing={4}>
                <Skeleton variant="rectangular" style={{ height: "200px", flex: 1, borderRadius: 10 }} />
                <Skeleton variant="rectangular" style={{ height: "200px", flex: 1, borderRadius: 10 }} />
                <Skeleton variant="rectangular" style={{ height: "200px", flex: 1, borderRadius: 10 }} />
                <Skeleton variant="rectangular" style={{ height: "200px", flex: 1, borderRadius: 10 }} />
            </Stack>
        </Stack>
    )
};

export default DashboardSkeleton;