export const calculeDistance = (loc1, loc2) => {
    // Inicio dos calculos 1° parte
    const p1 = Math.cos((90 - loc1.latitude) * (Math.PI / 180));
    // Inicio dos calculos 2° parte
    const p2 = Math.cos((90 - loc2.latitude) * (Math.PI / 180));
    // Inicio dos calculos 3° parte
    const p3 = Math.sin((90 - loc1.latitude) * (Math.PI / 180));
    // Inicio dos calculos 4° parte
    const p4 = Math.sin((90 - loc2.latitude) * (Math.PI / 180));
    // Inicio dos calculos 5° parte
    const p5 = Math.cos((loc1.longitude - loc2.longitude) * (Math.PI / 180));

    const KM = ((Math.acos((p1 * p2) + (p3 * p4 * p5)) * 6371) * 1.15);

    return KM;
}

export const formateDistanceToString = (distance) => {
    return distance > 1 ? `${Math.round(distance * 100) / 100} Km` : `${Math.round(distance * 100000) / 100} m`;
}