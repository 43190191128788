import React, { useState } from 'react';
import {Card, CardContent, TextField, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import {useJumboApp} from "@jumbo/hooks";
import {alpha} from "@mui/material/styles";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import api from 'app/services/config';

const RecuperarSenha = () => {
    const {setActiveLayout} = useJumboApp();

    const [email, setEmail] = useState("");

    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    }, []);

    async function resetPassword() {
        try {
            const { data } = await api.post("api/v1/password/reset", { email });

            console.log("DATA >> ", data);
        } catch (error) {
            console.log("ERROR > > > ", error);
        }
    }

    return (
        <Div sx={{width: 720, maxWidth: '100%', margin: 'auto', p: 4}}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/coffe.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0267a0', .65)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: {md: 320}
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h3"} color={"inherit"} fontWeight={500} mb={3}>
                                Recuperar Senha
                            </Typography>
                        </Div>
                        <Div sx={{mt: 'auto'}}>
                            <Link href="#" underline="none" sx={{display: 'inline-flex'}}>
                                <img style={{ width: 120 }} src={`${ASSET_IMAGES}/logo-mifix-white.png`} alt="Jumbo React"/>
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Div sx={{mt: 1, mb: 3}}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    id="email-address"
                                    label="E-mail"
                                />
                            </Div>
                            <Button
                                onClick={resetPassword}
                                variant="contained"
                            >
                                Recuperar
                            </Button>
                        </Div>
                        <Typography variant={"body1"} mt={'auto'}>
                            <Link href={"/login"} underline={'none'}>Login</Link>
                        </Typography>
                    </Div>
                </CardContent>
            </Card>

        </Div>
    );
};

export default RecuperarSenha;
