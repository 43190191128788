import React, {useEffect, useState} from 'react';
import ChartSalesOverview from "./ChartSalesOverview";
import Typography from "@mui/material/Typography";
import {Button, Input, TextField} from "@mui/material";
import {salesChartData} from "./data";
import Stack from "@mui/material/Stack";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import Div from '@jumbo/shared/Div/Div';

const SalesOverview = ({ data }) => {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState([]);
    
    useEffect(() => {
        setChartData(data);
    }, [data]);
    
    return (
        <JumboCardQuick
            noWrapper
            title={<Typography variant={"h4"}>Resumo de chamados</Typography>}
            /* action={
                <Stack direction={"row"} spacing={1}>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => setChartData(salesChartData.monthly)}
                    >
                        Monthly
                    </Button>
                    <Button
                        size={"small"}
                        variant={"contained"}
                        onClick={() => setChartData(salesChartData.yearly)}
                    >
                        Yearly
                    </Button>
                </Stack>
            } */
        >
            {/* <Stack
                direction="row"
                mb={5}
                mr={2}
                justifyContent="flex-end"
            >
                <Div sx={{ mr: 2 }}>
                    <TextField label="Início" />
                </Div>

                <Div>
                    <TextField label="Fim" />
                </Div>
            </Stack> */}

            <ChartSalesOverview data={chartData}/>
        </JumboCardQuick>
    );
};

export default SalesOverview;
