import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import localStorage  from 'redux-persist/lib/storage'
import {persistStore, persistReducer} from 'redux-persist';
import { useSelector } from "react-redux";


const history = createBrowserHistory({forceRefresh:true});
const routeMiddleware = routerMiddleware(history);
const bindMiddleware = middleware => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return composeEnhancers(applyMiddleware(...middleware));
};




function configureStore(initialState = {}) {
  
    const persistConfig = {
        key: "root",
        storage: localStorage 
    };

    const persistedReducer = persistReducer(persistConfig, reducers(history));
    
    const store = createStore(persistedReducer, initialState, bindMiddleware([routeMiddleware, thunk]));

    const persistor = persistStore(store)

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const exportReducers = require('../reducers');
            store.replaceReducer(exportReducers);
        });
    }
    return {store, persistor};
}
export default configureStore;
export { history };
