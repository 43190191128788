import Div from "@jumbo/shared/Div";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

export default function Item({ ticket, handleAssign }) {
    const ticketsStatus = ["Aberto", "Técnico a caminho", "Técnico chegou", "Resolvido", "Pendente", "Orfão", "Cancelado"];
    
    return (
        <Stack flexDirection="row" py={1}>
            <Div sx={{flexShrink: 0, px: 1}}>
                <Div
                    sx={{
                        mr: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #0DA594",
                        bgcolor: "#FFF",
                        borderRadius: "5px",
                        p: theme => theme.spacing(1, 2),
                        px: 1
                    }}
                >
                    <Typography
                        variant={"h6"}
                        color="#0DA594"
                        mb={0}
                    >
                        {ticket.id}
                    </Typography>
                </Div>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={.25}
                >
                    OS
                </Typography>
                <Typography variant={"body1"}>#{ticket.os}</Typography>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={.25}
                >
                    Solicitante
                </Typography>
                <Typography variant={"body1"}>{ticket.isBackOffice ? "BackOffice" : ticket.client}</Typography>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={.25}
                >
                    Status
                </Typography>
                <Typography variant={"body1"}>{ticketsStatus[ticket.ticket_status_id - 1]}</Typography>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={.25}
                >
                    Endereço
                </Typography>
                <Typography variant={"body1"}>{ticket.endereco}</Typography>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Typography
                    fontSize={"12px"}
                    variant={"h6"}
                    color={"text.secondary"}
                    mb={.25}
                >
                    Tempo
                </Typography>
                <Typography variant={"body1"}>{ticket.tempo_chamado || "N/A"}</Typography>
            </Div>

            <Div
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
            >
                <Button
                    onClick={() => handleAssign(ticket.id)}
                    variant="outlined"
                    color="success"
                >
                    Atribuir
                </Button>
            </Div>
        </Stack>
    );
}