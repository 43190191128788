export function login(data, token){
    return {
        type: "@mybway_login",
        payload: {
            data,
            token,
        }
    }

}

export function logout(){
    return{
        type: "@mybway_logout",
        payload: null
    }

}


