import React, {useState, useEffect, useCallback, useRef} from 'react';
import Typography from "@mui/material/Typography";
import {GoogleMap, InfoWindow, Marker, useLoadScript, DirectionsRenderer, Polyline } from "@react-google-maps/api";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {useTranslation} from "react-i18next";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import moment from 'moment';
import Div from '@jumbo/shared/Div/Div';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PushPinIcon from '@mui/icons-material/PushPin';

const MapPopupInfo = ({ historico, tickets }) => {
    const {t} = useTranslation();
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: "AIzaSyCQmjUo21XAXUWVFElZbg5bROsoyGDvTTQ",
    })

    const [markers, setMarkers] = useState([]);
    const [markersTickets, setMarkersTickets] = useState([]);
    const [directions, setDirections] = useState(null);

    const [path, setPath] = useState([
        { lat: 52.52549080781086, lng: 13.398118538856465 },
        { lat: 52.48578559055679, lng: 13.36653284549709 },
        { lat: 52.48871246221608, lng: 13.44618372440334 }
    ]);

    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    const onEdit = useCallback(() => {
        if (polygonRef.current) {
          const nextPath = polygonRef.current
            .getPath()
            .getArray()
            .map(latLng => {
              return { lat: latLng.lat(), lng: latLng.lng() };
            });
          setPath(nextPath);
        }
      }, [setPath]);
    
      // Bind refs to current Polygon and listeners
      const onLoad = useCallback(
        polygon => {
          polygonRef.current = polygon;
          const path = polygon.getPath();
          listenersRef.current.push(
            path.addListener("set_at", onEdit),
            path.addListener("insert_at", onEdit),
            path.addListener("remove_at", onEdit)
          );
        },
        [onEdit]
      );
    
      // Clean up refs
      const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
      }, []);

    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });

    useEffect(() => {
        if(historico) {
            const center = {
                lat: 0,
                lng: 0
            };

            const nextPath = [];

            setMarkers(historico.logs.map((log, index) => {
                center['lat'] += Number(log.latitude);
                center['lng'] += Number(log.longitude);

                nextPath.push(
                    {lat: Number(log.latitude), lng: Number(log.longitude)}
                );

                return {
                    position: {
                        lat: Number(log.latitude),
                        lng: Number(log.longitude),
                    },//new window.google.maps.LatLng(47.646145, -122.303763),
                    showInfo: false,
                    infoContent: (
                        <div className={"d-flex"}>
                            <div>
                                <LocationOnIcon sx={{ fontSize: 20 }} />
                            </div>
                            <div ml={1}>
                                <p>Passou por {log.endereco} em {moment(log.created_at).format("DD/MM/YYYY HH:mm")}</p>
                            </div>
                        </div>
                    ),
                }
            }));

            setMarkersTickets(tickets.map(ticket => {

                return {
                    position: {
                        lat: Number(ticket.latitude),
                        lng: Number(ticket.longitude),
                    },//new window.google.maps.LatLng(47.646145, -122.303763),
                    showInfo: false,
                    os: ticket.os,
                    infoContent: (
                        <div className={"d-flex"}>
                            <div>
                                <PushPinIcon sx={{ fontSize: 20 }} color='#228b22' />
                            </div>
                            <div ml={1}>
                                <p>Atendimento realizado em {moment(ticket.data_de_resolucao).format("DD/MM/YYYY HH:mm")}</p>
                            </div>
                        </div>
                    ),
                }
            }))

            center['lat'] = center['lat'] / historico.logs.length;
            center['lng'] = center['lng'] / historico.logs.length;

            setPath(nextPath);
            setCenter(center);
        }
    }, [historico]);

    // Toggle to 'true' to show InfoWindow and re-renders simple
    const handleMarkerClick = targetMarker => {
        setMarkers(() => {
            return markers.map(marker => {
                if (marker === targetMarker) {
                    return {
                        ...marker,
                        showInfo: true,
                    };
                }
                return {
                    ...marker,
                    showInfo: false,
                };
            });
        });
    };

    const handleMarkerTicketsClick = targetMarker => {
        setMarkersTickets(() => {
            return markersTickets.map(marker => {
                if (marker === targetMarker) {
                    return {
                        ...marker,
                        showInfo: true,
                    };
                }
                return {
                    ...marker,
                    showInfo: false,
                };
            });
        });
    };

    const handleMarkerClose = targetMarker => {
        setMarkersTickets(() => {
            return markersTickets.map(marker => {
                if (marker === targetMarker) {
                    return {
                        ...marker,
                        showInfo: false,
                    };
                }
                return marker;
            });
        });
    };

    const handleMarkerTicketsClose = targetMarker => {
        setMarkers(() => {
            return markers.map(marker => {
                if (marker === targetMarker) {
                    return {
                        ...marker,
                        showInfo: false,
                    };
                }
                return marker;
            });
        });
    };

    return (
        <React.Fragment>
            <Div sx={{p: 5}}>
                {
                    isLoaded &&
                    <GoogleMap
                        mapContainerStyle={{width: '100%', height: "600px"}}
                        center={center}
                        zoom={15}
                    >
                        {
                            markers.map((marker, index) => (
                                <Marker
                                    icon={getAssetPath(`${ASSET_IMAGES}/marker.png`)}
                                    /* label={`${index + 1}`} */
                                    label={{
                                        text: `${index + 1}`,
                                        color: "#FFF"
                                    }}
                                    key={index}
                                    position={marker.position}
                                    onClick={() => handleMarkerClick(marker)}
                                >
                                    {marker.showInfo && (
                                        <InfoWindow onCloseClick={() => handleMarkerClose(marker)}>
                                            <div>{marker.infoContent}</div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))
                        }

                        {
                            markersTickets.map((marker, index) => (
                                <Marker
                                    /* label={`${index + 1}`} */
                                    label={{
                                        text: `#${marker.os}`,
                                        color: "#040404",
                                        className: "marker-ticket-text"
                                    }}
                                    key={index}
                                    position={marker.position}
                                    onClick={() => handleMarkerTicketsClick(marker)}
                                >
                                    {marker.showInfo && (
                                        <InfoWindow onCloseClick={() => handleMarkerTicketsClose(marker)}>
                                            <div>{marker.infoContent}</div>
                                        </InfoWindow>
                                    )}
                                </Marker>
                            ))
                        }

                        <Polyline
                            editable={false}
                            draggable={false}
                            path={path}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        />
                    </GoogleMap>
                }
            </Div>
        </React.Fragment>
    );
};

export default MapPopupInfo;
