import React from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import Lottie from "lottie-react";
import chatAnimation from "../../../../../assets/chat-no-bg.json";

const ContentPlaceholder = () => {
    return (
        <Div
            sx={{
                textAlign: 'center',
                margin: 'auto',
                p: 3
            }}
        >
            <Lottie animationData={chatAnimation} height={350} />
            {/* <img src={getAssetPath(`${ASSET_IMAGES}/apps/undraw_development.svg`, '800x600')} alt="Welcome to Jumbo Chat App" width={350}/> */}
            <Typography variant={"h2"} mt={2} color={"text.primary"}>Converse com um técnico online</Typography>
        </Div>
    );
};

export default ContentPlaceholder;
