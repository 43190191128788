import React from 'react';
import ConnectionsList from "./ConnectionsList";
import Button from "@mui/material/Button";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useTranslation} from "react-i18next";
import { history } from 'app/redux/store';
import Lottie from "lottie-react";
import emptyAnimation from "../../../assets/emptyAtividades.json";
import { Stack } from '@mui/material';

const NewConnections = ({scrollHeight, data}) => {
    const {t} = useTranslation();

    return (
        <JumboCardQuick
            title={t('Novos Técnicos Aguardando Aprovação')}
            subheader={t(`${data.length} técnicos pendentes`)}
            action={<Button variant={"contained"} size={"small"} onClick={() => history.push("usuarios/tecnicos?status=Pendente")}>Ver todos</Button>}
            wrapperSx={{p: 0}}
        >
            {
                data.length > 0 ? (
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={scrollHeight ? scrollHeight : 278}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                        <ConnectionsList data={data} />
                    </JumboScrollbar>
                ) : (
                    <Stack>
                        <Lottie
                            animationData={emptyAnimation}
                            style={{
                                height: 278,
                                margin: 0
                            }}
                        />
                    </Stack>
                )
            }
        </JumboCardQuick>
    )
};
/* Todo scrollHeight prop define */
export default NewConnections;
