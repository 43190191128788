import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import {
  Button,
  CardHeader,
  Divider,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Autocomplete,
  CircularProgress,
  Grid,
} from "@mui/material";
import ScheduleItem from "./ScheduleItem";
import { useSelector } from "react-redux";
import Timeline from "@mui/lab/Timeline";
import { CloseOutlined } from "@mui/icons-material";
import * as Yup from "yup";
import { useFormik } from "formik";
import api, { apiPilao } from "../../services/config";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Geocode from "react-geocode";
import { socketTicket } from "app/services/socket";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  maxHeight: "95%",
  overflowY: "scroll",
};

const ModalNovoChamado = ({ open, setOpen, user_id, refresh }) => {
  Geocode.setApiKey("AIzaSyDA5mGn5b68YB_gD1vWyKehLz89U00vOtM");

  const handleClose = () => setOpen(false);

  const { auth } = useSelector((state) => state);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [problemas, setProblemas] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [instalacao, setInstalacao] = useState(false);
  const validationSchema = Yup.object().shape({
    // equipamento_nome: Yup.string().required("Campo obrigatório"),
    endereco: Yup.string().required("Campo obrigatório"),
    qrcode: Yup.string().required("Campo obrigatório"),
    // equipamento_numero: Yup.string().required("Campo obrigatório"),
    equipamento_complemento: Yup.string().optional(),
    empresa: Yup.string().optional(),
    ocorrencia_reportada: Yup.string().required("Campo obrigatório"),
    // client: Yup.string().required("Campo obrigatório"),
    comment: Yup.string().required("Campo obrigatório"),
    latitude: Yup.string().optional(),
    longitude: Yup.string().optional(),
    codcli: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      equipamento_modelo: "",
      segmento: "",
      endereco: "",
      equipamento_numero: "",
      equipamento_complemento: "",
      empresa: "",
      ocorrencia_reportada: "",
      client: "",
      comment: "",
      qrcode: "",
      latitude: "",
      longitude: "",
      codcli: "",
    },
    validationSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    getProblems();
  }, []);

  async function submitForm(ticket) {
    setLoading(true);

    const endereco = ticket["endereco"].split(" - ");
    ticket["isBackOffice"] = true;

    ticket[
      "endereco"
    ] = `${endereco[0]}, ${ticket["equipamento_numero"]} - ${endereco[1]}`;
    ticket["cliente_id"] = auth.data?.user?.id;
    ticket["city"] = city;
    ticket["state"] = state;
    ticket["equipamento_nome"] = ticket["qrcode"];
    ticket["client"] = ticket["empresa"];

    await Geocode.fromAddress(endereco)
      .then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          ticket["latitude"] = lat;
          ticket["longitude"] = lng;
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => {
        console.log(error);
      });

    ticket["equipamento_numero"] =
      ticket["equipamento_numero"] === "" ? 0 : ticket["equipamento_numero"];

    try {
      const { data } = await api.post("api/v2/dashboard/tickets", ticket, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log(".>>", data);

      if (data?.status === 400) {
        alert(data.message, [
          {
            text: "OK",
          },
        ]);
        setLoading(false);

        return;
      }

      if (user_id) {
        await insereFila(data.id);

        socketTicket.emit("admin-assign", {
          tecnico_id: parseInt(user_id),
          ticket: data.ticket,
        });

        refresh();
      }

      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log("ERROR > > ", error);
    }
    setLoading(false);
  }

  async function insereFila(ticket_id) {
    try {
      const { data } = await api.post(
        "api/v2/dashboard/fila",
        {
          ticket_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log("ERROR > > ", error);
    }
  }

  async function getMachineAddress() {
    setLoading(true);
    try {
      const { data } = await apiPilao.get(
        `mifix/consulta/ativo/${formik.values.qrcode}`
      );

      if (formik.values.qrcode.toLowerCase() === "000a") {
        setInstalacao(true);
        formik.setFieldValue("empresa", "");
        setEmpresa("");
        formik.setFieldValue("endereco", "");
        formik.setFieldValue("segmento", "");
        formik.setFieldValue("equipamento_modelo", "");
        formik.setFieldValue("equipamento_numero", "");
        formik.setFieldValue("equipamento_complemento", "");
        formik.setFieldValue("codcli", "");
        formik.setFieldValue("latitude", "");
        formik.setFieldValue("longitude", "");
        setLoading(false);
        return;
      }
      setInstalacao(false);
      if (!data.ENDERECO) {
        setLoading(false);
        return alert(
          "Equipamento não encontrado, verifique o número da matrícula ou tente novamente mais tarde."
        );
      }

      let endereco = `${data.ENDERECO} - ${data.NUMERO}, ${data.BAIRRO} - ${data.CIDADE}, ${data.UF}`;

      setState(data.UF);
      setCity(data.CIDADE);
      formik.setFieldValue("empresa", data.Cliente);
      setEmpresa(data.Cliente);
      formik.setFieldValue("endereco", endereco);
      formik.setFieldValue("segmento", data.SEGMENTO);
      formik.setFieldValue("equipamento_modelo", data.MODELO);
      formik.setFieldValue("equipamento_numero", data.NUMERO);
      formik.setFieldValue("equipamento_complemento", data.COMPLEMENTO);
      formik.setFieldValue("codcli", data.CODCLI);
      formik.setFieldValue("latitude", data.LAT);
      formik.setFieldValue("longitude", data.LONG);
    } catch (error) {
      alert("Equipamento não encontrado");
      console.log("ERROR GET MACHINE ADDRESS > > ", error);
    }
    setLoading(false);
  }

  const equipamentos_tipos = [
    "Equipamento Café e Bebidas Quentes",
    "Equipamento Snacks/Salgadinhos",
    "Equipamento Bebidas Frias/Refrigerantes",
  ];

  async function getProblems() {
    if (instalacao) {
      const objInstalacao = [
        {
          descricao: "Instalação",
          id: 1,
          equipamento_tipo: 1,
        },
      ];
      setProblemas(objInstalacao);
      return;
    }

    try {
      const { data } = await api.get(`/api/v1/problems/1`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setProblemas(data.problems);
    } catch (error) {
      console.log("ERROR > > ", error);
    }
  }

  useEffect(() => {
    getProblems();
  }, [instalacao]);

  return (
    <Div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Div sx={style}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={1}
            >
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
              >
                {user_id ? "Adicionar Chamado" : "Emitir chamado para todos"}
              </Typography>

              <Button color="error" onClick={() => setOpen(false)}>
                <CloseOutlined />
              </Button>
            </Stack>

            <Divider sx={{ mb: 2 }} />

            <Stack flexDirection="row">
              <form
                autoComplete="off"
                style={{ display: "flex", flex: 1 }}
                onSubmit={formik.handleSubmit}
              >
                <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                  <CardHeader title="Equipamento" sx={{ mt: -1, ml: -1 }} />
                  {/* 
                  <FormControl>
                    <TextField
                      fullWidth
                      select
                      id="equipamento_nome"
                      name="equipamento_nome"
                      label="Tipo de equipamento"
                      value={formik.values.equipamento_nome}
                      onChange={(e) => {
                        getProblems(e.target.value);
                        formik.handleChange(e);
                      }}
                      error={
                        formik.touched.equipamento_nome &&
                        Boolean(formik.errors.equipamento_nome)
                      }
                      helperText={
                        formik.touched.equipamento_nome &&
                        formik.errors.equipamento_nome
                      }
                      InputProps={{
                        autoComplete: "off",
                      }}
                    >
                      <MenuItem value="qrcode">QRCODE</MenuItem>
                      <MenuItem value="Equipamento Café e Bebidas Quentes">
                        Equipamento Café e Bebidas Quentes
                      </MenuItem>
                      <MenuItem value="Equipamento Snacks/Salguadinhos">
                        Equipamento Snacks/Salguadinhos
                      </MenuItem>
                      <MenuItem value="Equipamento Bebidas Frias/Refrigerantes">
                        Equipamento Bebidas Frias/Refrigerantes
                      </MenuItem>
                    </TextField>
                  </FormControl> */}

                  <FormControl sx={{ mt: 2 }}>
                    <TextField
                      fullWidth
                      id="qrcode"
                      name="qrcode"
                      label="Nome do equipamento"
                      value={formik.values.qrcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.qrcode && Boolean(formik.errors.qrcode)
                      }
                      helperText={formik.touched.qrcode && formik.errors.qrcode}
                      onBlur={getMachineAddress}
                    />
                  </FormControl>

                  <CardHeader
                    title={!instalacao ? "Endereço" : "Endereço da instalação"}
                    sx={{ ml: -1 }}
                  />

                  <FormControl sx={{ mb: 2, width: "100%" }}>
                    <GooglePlacesAutocomplete
                      style={{ zIndex: 199 }}
                      apiKey="AIzaSyDA5mGn5b68YB_gD1vWyKehLz89U00vOtM"
                      fullWidth
                      id="endereco"
                      label="Endereço"
                      error={
                        formik.touched.endereco &&
                        Boolean(formik.errors.endereco)
                      }
                      helperText={
                        formik.touched.endereco && formik.errors.endereco
                      }
                      selectProps={{
                        id: "endereco",
                        placeholder: formik.values.endereco || "Endereço",
                        onChange: (endereco) => {
                          formik.setFieldValue("endereco", endereco.label);
                          Geocode.fromAddress(endereco.label)
                            .then(
                              (response) => {
                                const cityResp =
                                  response.results[0].address_components.filter(
                                    (item) =>
                                      item.types.includes(
                                        "administrative_area_level_2"
                                      )
                                  );
                                const stateResp =
                                  response.results[0].address_components.filter(
                                    (item) =>
                                      item.types.includes(
                                        "administrative_area_level_1"
                                      )
                                  );

                                setCity(cityResp[0].long_name);
                                setState(stateResp[0].short_name);

                                const { lat, lng } =
                                  response.results[0].geometry.location;
                                formik.setFieldValue("latitude", lat);
                                formik.setFieldValue("longitude", lng);
                              },
                              (error) => {
                                console.error(error);
                              }
                            )
                            .catch((error) => {
                              console.log(error);
                            });
                        },
                      }}
                      apiOptions={{ language: "pt-br", region: "br" }}
                      inputProps={{ autoComplete: "off" }}
                    />
                    <TextField
                      fullWidth
                      InputProps={{
                        style: {
                          display: "none",
                        },
                      }}
                      error={
                        formik.touched.endereco &&
                        Boolean(formik.errors.endereco)
                      }
                      helperText={
                        formik.touched.endereco && formik.errors.endereco
                      }
                    />
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl sx={{ mb: 2, width: "100%" }}>
                        <TextField
                          style={{ zIndex: 0 }}
                          fullWidth
                          id="equipamento_numero"
                          label="Número"
                          InputProps={{
                            autoComplete: "off",
                          }}
                          value={formik.values.equipamento_numero}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.equipamento_numero &&
                            Boolean(formik.errors.equipamento_numero)
                          }
                          helperText={
                            formik.touched.equipamento_numero &&
                            formik.errors.equipamento_numero
                          }
                        />

                        <TextField
                          fullWidth
                          id="latitude"
                          name="latitude"
                          style={{ display: "none" }}
                        />
                        <TextField
                          fullWidth
                          id="longitude"
                          name="longitude"
                          style={{ display: "none" }}
                        />
                        <TextField
                          fullWidth
                          id="codcli"
                          name="codcli"
                          style={{ display: "none" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl sx={{ mb: 2, width: "100%" }}>
                        <TextField
                          fullWidth
                          style={{ zIndex: 0 }}
                          id="equipamento_complemento"
                          label="Complemento"
                          onChange={formik.handleChange}
                          InputProps={{
                            autoComplete: "off",
                          }}
                          value={formik.values.equipamento_complemento}
                          error={
                            formik.touched.equipamento_complemento &&
                            Boolean(formik.errors.equipamento_complemento)
                          }
                          helperText={
                            formik.touched.equipamento_complemento &&
                            formik.errors.equipamento_complemento
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      disabled={!instalacao}
                      style={{ zIndex: 0 }}
                      onChange={formik.handleChange}
                      id="empresa"
                      name="empresa"
                      label="Empresa"
                      value={formik.values.empresa}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </FormControl>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl
                        sx={{ mb: 2, display: instalacao ? "block" : "none" }}
                      >
                        <TextField
                          fullWidth
                          onChange={formik.handleChange}
                          id="segmento"
                          name="segmento"
                          label="segmento"
                          value={formik.values.segmento}
                          InputProps={{
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        sx={{ mb: 2, display: instalacao ? "block" : "none" }}
                      >
                        <TextField
                          fullWidth
                          onChange={formik.handleChange}
                          id="equipamento_modelo"
                          name="equipamento_modelo"
                          label="Modelo do Equipamento"
                          value={formik.values.equipamento_modelo}
                          InputProps={{
                            autoComplete: "off",
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <CardHeader title="Ocorrência" sx={{ mt: -1, ml: -1 }} />

                  <FormControl sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      style={{ zIndex: 0 }}
                      select
                      id="ocorrencia_reportada"
                      name="ocorrencia_reportada"
                      label="Ocorrência"
                      InputProps={{
                        autoComplete: "off",
                      }}
                      value={formik.values.ocorrencia_reportada}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.ocorrencia_reportada &&
                        Boolean(formik.errors.ocorrencia_reportada)
                      }
                      helperText={
                        formik.touched.ocorrencia_reportada &&
                        formik.errors.ocorrencia_reportada
                      }
                    >
                      {problemas.map((problema) => (
                        <MenuItem value={problema.descricao}>
                          {problema.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  {/* <FormControl sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      style={{ zIndex: 0 }}
                      id="client"
                      label="Cliente"
                      InputProps={{
                        autoComplete: "off",
                      }}
                      value={formik.values.client}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={formik.touched.client && formik.errors.client}
                    />
                  </FormControl> */}
                  <FormControl sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      style={{ zIndex: 0 }}
                      rows={4}
                      id="comment"
                      label="Descrição"
                      multiline
                      InputProps={{
                        autoComplete: "off",
                      }}
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.comment && Boolean(formik.errors.comment)
                      }
                      helperText={
                        formik.touched.comment && formik.errors.comment
                      }
                    />
                  </FormControl>

                  <Stack flexDirection="row" justifyContent={"flex-start"}>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        formik.resetForm();
                        setOpen(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                      Emitir
                    </Button>
                  </Stack>
                </Div>
              </form>

              <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <CardHeader
                  title="Chamado"
                  component="h1"
                  sx={{ mt: -1, ml: -1 }}
                />

                <Timeline
                  sx={{
                    mt: 0,
                    pt: 0,
                  }}
                >
                  <ScheduleItem
                    item={{
                      title: "Equipamento",
                      subTitle:
                        formik.values["equipamento_nome"] == "qrcode"
                          ? formik.values["qrcode"]
                          : formik.values["equipamento_nome"],
                    }}
                  />
                  <ScheduleItem
                    item={{
                      title: "Endereço",
                      subTitle: formik.values["endereco"],
                    }}
                  />
                  <ScheduleItem
                    item={{
                      title: "Número",
                      subTitle: formik.values["equipamento_numero"],
                    }}
                  />
                  <ScheduleItem
                    item={{
                      title: "Complemento",
                      subTitle: formik.values["equipamento_complemento"],
                    }}
                  />
                  <ScheduleItem
                    item={{ title: "Empresa", subTitle: empresa }}
                  />
                  <ScheduleItem
                    item={{
                      title: "Ocorrência",
                      subTitle: formik.values["ocorrencia_reportada"],
                    }}
                  />
                  {/* <ScheduleItem
                    item={{
                      title: "Cliente",
                      subTitle: formik.values["client"],
                    }}
                  /> */}
                  <ScheduleItem
                    item={{
                      title: "Descrição",
                      subTitle: formik.values["comment"],
                    }}
                  />
                  <ScheduleItem
                    item={{
                      title: "Solicitante",
                      subTitle: `${
                        auth ? auth.data?.user?.name : ""
                      } (BackOffice)`,
                    }}
                  />
                </Timeline>
              </Div>
            </Stack>
          </Div>
        </Fade>
      </Modal>
    </Div>
  );
};
export default ModalNovoChamado;
