import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from "./contactsApp"
import auth from '../modules/auth/reducer'
import settings from '../modules/settings/reducer'

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        auth: auth,
        settings
    });
};

export default exportReducers;

