import Div from "@jumbo/shared/Div/Div";
import { Alert, Autocomplete, Backdrop, Button, CardHeader, CircularProgress, Fade, FormControl, Modal, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../services/config";
import TransferList from "./TransferList";

const ModalPermissao = ({ open, setOpen }) => {
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [users, setUsers] = useState([]);
    const [adms, setAdms] = useState([]);
    const [permissionIds, setPermissionIds] = useState(null);
    const [tecsIds, setTecsIds] = useState([]);
    const [tecsPrev, setTecsPrev] = useState();
    const [success, setSuccess] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: 5,
        minWidth: "80%",
        maxHeight: "80%",
        overflowY: "scroll",
        boxShadow: 24,
        p: 5
    };

    async function fetchUsuáriosListagem(s) {
        if(s.length <= 1) return;

        setSearching(true);
        try {
            const { data } = await api.get("api/v2/dashboard/listagem/usuarios", { params: { s } });

            setAdms(data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearching(false);
    }

    async function fetchTecnicosListagem(s) {
        if(s.length <= 1) {

            return;
        }

        setSearching(true);
        try {
            const { data } = await api.get("api/v2/dashboard/listagem/tecnicos", { params: { s } });

            console.log(data);
            setUsers(data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearching(false);
    }

    async function savePermissions() {
        setLoading(true);
        const permissions = [];

        /* for (let i = 0; i < permissionIds.length; i++) {
            const user_id = permissionIds[i];
             */
        for (let j = 0; j < tecsIds.length; j++) {
            const tec_id = tecsIds[j];
            
            permissions.push({ user_id: permissionIds, tec_id });
        }
        /* } */

        try {
            const {data} = await api.post("api/v2/permissao-historico-tecnico", { data: permissions, users_id: [permissionIds] });

            console.log("RESPONDE > > ", data);
            setSuccess(true);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setLoading(false);
    }

    useEffect(() => {
        setSuccess(false);
    }, [open]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Div sx={style}>
                    {
                        success && (
                            <Alert severity="success">Permissões registradas com sucesso!</Alert>
                        )
                    }

                    <Stack direction="row" sx={{ mb: 5 }}>
                        <Div sx={{ display: "flex", flex: 1, flexDirection: "column", mr: 2 }}>
                            <CardHeader title="Procure por usuários" />
                            
                            <Autocomplete
                                sx={{ width: "100%", mt: 0 }}
                                id="tags-standard"
                                options={adms}
                                getOptionLabel={(option) => option.name}
                                blurOnSelect
                                onChange={(event, newValue) => {
                                    setPermissionIds(newValue.id);
                                    console.log(newValue);

                                    if(newValue.tec_permissions) {
                                        const ids = [];
                                        const prev = [];
                                        newValue.tec_permissions.map(tec => {
                                            ids.push(tec.tecnicos.id);
                                            prev.push(tec.tecnicos);
                                        })

                                        console.log(ids, prev);
                                        setTecsIds(ids);
                                        setTecsPrev(prev);
                                    }

                                }}
                                /* onBlur={() => setUsers([])} */
                                noOptionsText="Buscar Usuários..."
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Usuários"
                                        placeholder="Selecione..."
                                        onChange={e => fetchUsuáriosListagem(e.target.value)}
                                        sx={{ width: "100%" }}
                                    />
                                )}
                            />
                            {/* <Stack mb={5}>
                                <Stack sx={{ display: "flex", flex: 1, mr: 2, mb: 2 }}>
                                    <Stack sx={{ mb: 2 }}>
                                        <Div>
                                            <FormControl sx={{width: "100%"}}>
                                                <TextField
                                                    label="Buscar Usuários..."
                                                    onChange={(e) => {
                                                        fetchUsuáriosListagem(e.target.value);
                                                    }}
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Div>
                                    </Stack>
                                </Stack>

                                <TransferList data={adms} selectItems={setPermissionIds} />
                            </Stack> */}
                        </Div>

                        {
                            permissionIds && (
                                <Div sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                    <CardHeader title="Selecione os técnicos" />

                                    <Autocomplete
                                        multiple
                                        sx={{ width: "100%", mt: 0 }}
                                        id="tags-standard"
                                        value={tecsPrev}
                                        options={users}
                                        getOptionLabel={(option) => option.name}
                                        blurOnSelect
                                        onChange={(event, newValue) => {
                                            setTecsIds(newValue.map(it => it.id));
                                            setTecsPrev(newValue);
                                        }}
                                        /* onBlur={() => setUsers([])} */
                                        noOptionsText="Busque pelo técnico..."
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Técnicos"
                                                placeholder="Selecione..."
                                                onChange={e => fetchTecnicosListagem(e.target.value)}
                                                sx={{ width: "100%" }}
                                            />
                                        )}
                                    />
                                </Div>
                            )
                        }
                    </Stack>

                    <Button
                        variant="contained"
                        color="info"
                        sx={{ width: "100%" }}
                        onClick={savePermissions}
                        disabled={loading}
                    >
                        {
                            loading ? <CircularProgress size={25} sx={{ color: "#FFF" }} /> : "Conceder Permissão"
                        }
                    </Button>
                </Div>
            </Fade>
        </Modal>
    );
}

export default ModalPermissao;