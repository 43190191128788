import baseAxios from "axios";

let apiHost = "";

switch (process.env.REACT_APP_ENV) {
  case "localhost": {
    apiHost = "http://localhost:8000";
    break;
  }
  case "development": {
    apiHost = "https://api.mifix.dev.focalweb.com.br";
    break;
  }
  case "production": {
    apiHost = "https://admin.mifix.com.br";
    break;
  }
}

const axios = baseAxios.create({
  baseURL: apiHost,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiPilao = baseAxios.create({
  baseURL: "https://slweb.slaplic.com.br:3001",
  headers: {
    "Content-Type": "application/json",
  },
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;
