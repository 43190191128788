const INITIAL_STATE = {
    token: "",
    data: "",
    
}

export default function auth(state = INITIAL_STATE, action){
    switch(action.type){
        case "@mybway_login":
            return{
                ...state, 
                ...action.payload
            }
        case "@mybway_logout":
            return null
        default:
            return state
    }
}