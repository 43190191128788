import React, {Suspense, useState} from 'react';
import {CircularProgress, IconButton} from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {useJumboLayoutSidebar, useJumboSidebarTheme} from "@jumbo/hooks";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import {red} from "@mui/material/colors";
import SidebarSkeleton from "./SidebarSkeleton";
import {sidebarTheme as theme1} from "../../../../themes/sidebar/theme1";
import { useSelector } from 'react-redux';






const Sidebar = () => {
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();
    const { auth } = useSelector(state => state);

    const [appMenus, setAppMenus] = useState(menus);

    React.useEffect(() => {
        setSidebarTheme({
            ...sidebarTheme,
            ...theme1,
            overlay: {
                // backgroundImage: `linear-gradient(${activeBgColorOption?.colors[0]}, ${activeBgColorOption?.colors[1]})`,
                bgColor:  ["#03ce97", "#00619a"],
                opacity: 0.85
            }
        })
    }, []);

    React.useEffect(() => {
        if(auth && auth.data && ((!auth.data.hasPermissionLocation) && (auth.data.user.roles && auth.data.user.roles[0].name != "Administrador Level 2"))) {
            const menuTemp = menus;

            menuTemp[1].children = menuTemp[1].children.filter(children => children.label != "Localização");

            setAppMenus(menuTemp);
        }
    }, []);
    
    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={appMenus}/>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};



const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme, setSidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);





    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
