import React, {useCallback, useEffect, useState} from 'react';
import {connections} from "./data";
import {List} from "@mui/material";
import ConnectionItem from "./ConnectionItem";

const ConnectionsList = ({ data }) => {
    const [itemsList, setItemsList] = useState([]);

    useEffect(() => {
        setItemsList(data);
    }, [data]);

    return (
            <List disablePadding>
                {
                    itemsList.map((item, index) => (
                        <ConnectionItem item={item} key={index} />
                    ))
                }
            </List>
    );
};

export default ConnectionsList;
