import Div from "@jumbo/shared/Div/Div";
import { Avatar, Backdrop, Button, CardHeader, Chip, CircularProgress, Fade, FormControl, Modal, Stack, Tab, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import api from "../../../services/config";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import moment from "moment";
import { useSelector } from "react-redux";

const ModalTecnicos = ({ open, setOpen, handleGetHistorico }) => {
    const { auth } = useSelector(state => state);
    const [searching, setSearching] = useState(false);
    const [users, setUsers] = useState([]);
    const [tab, setTab] = useState("tecnico");
    const [dates, setDates] = useState([moment().startOf("M").format("YYYY-MM-DD"), moment().endOf("M").format("YYYY-MM-DD")]);
    const [selectedUser, setSelectedUser] = useState(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: 5,
        minWidth: "80%",
        maxHeight: "80%",
        overflowY: "scroll",
        boxShadow: 24,
        p: 5
    };

    async function fetchTecnicosListagem(s) {
        if(s.length <= 1) return;

        setSearching(true);
        try {
            let route = "";
            
            if(auth.data.hasPermissionLocation) {
                route = "api/v2/dashboard/permissao/listagem/tecnicos";
            }
            
            if(auth.data.user.roles[0].name == "Administrador Level 2") {
                route = "api/v2/dashboard/listagem/tecnicos";
            }

            const { data } = await api.get(route, { params: { s, auth_id: auth.data.user.id } });

            console.log(data);
            setUsers(data);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearching(false);
    }

    useEffect(() => {
        setUsers([]);
        setSelectedUser(null);
        /* if(!selectedUser) {
            setUsers([]);
            setSelectedUser(null);
        } */
        setDates([moment().startOf("M").format("YYYY-MM-DD"), moment().endOf("M").format("YYYY-MM-DD")]);
    }, [open]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Div sx={style}>
                    <CardHeader title="Selecione o técnico e um período" />
                    <Stack direction="row" mb={5}>
                        <Stack sx={{ display: "flex", flex: 1, mr: 2, mb: 2 }}>
                            <Stack sx={{ mb: 2 }}>
                                <Div>
                                    <FormControl sx={{width: "100%"}}>
                                        <TextField
                                            label="Buscar Técnico..."
                                            onChange={(e) => {
                                                setSelectedUser(null);
                                                fetchTecnicosListagem(e.target.value);
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Div>
                            </Stack>

                            <Stack>
                                {
                                    searching ? (
                                        <Div sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </Div>
                                    ) : (
                                        <Div>
                                            {
                                                users.map(user => (
                                                    <Stack
                                                        sx={{    
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            mb: 2,
                                                            bgcolor: (selectedUser && selectedUser.id == user.id) ? "#008598" : "#f5f5f5",
                                                            padding: 2,
                                                            borderRadius: 5,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            setSelectedUser(user);
                                                            setUsers([user]);
                                                        }}
                                                    >
                                                        <Avatar
                                                            alt={user.name}
                                                            src={`https://storage.googleapis.com/uteky/images/tecnicos/${user.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                                                            sx={{width: 50, height: 50, mr: 2}}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                color: (selectedUser && selectedUser.id == user.id) ? "#FFF" : "#040404"
                                                            }}    
                                                        >
                                                            {user.name}
                                                        </Typography>
                                                    </Stack>
                                                ))
                                            }
                                        </Div>
                                    )
                                }
                            </Stack>
                        </Stack>

                        <Stack
                            sx={{ display: "flex", flex: 1 }}
                            /* value={"periodo"}
                            sx={{ display: "flex", flex: 1 }} */
                        >
                            <Stack direction="row">
                                <FormControl sx={{ display: "flex", flex: 1, mr: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Início"
                                        type="date"
                                        value={dates[0]}
                                        onChange={e => setDates([e.target.value, dates[1]])}
                                        focused
                                    />
                                </FormControl>

                                <FormControl sx={{ display: "flex", flex: 1 }}>
                                    <TextField
                                        fullWidth
                                        label="Final"
                                        type="date"
                                        value={dates[1]}
                                        onChange={e => setDates([dates[0], e.target.value])}
                                        focused
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Button
                        variant="contained"
                        color="info"
                        sx={{ width: "100%" }}
                        onClick={() => handleGetHistorico(selectedUser, dates)}
                    >
                        Procurar Histórico
                    </Button>
                </Div>
            </Fade>
        </Modal>
    );
}

export default ModalTecnicos;