import React from 'react';
import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import { history } from 'app/redux/store';

const ConnectionItem = ({item}) => {
    return (
        <ListItem sx={{p: theme => theme.spacing(1, 3)}}>
            <ListItemAvatar>
                <Avatar
                    alt={item.name}
                    src={`https://storage.googleapis.com/uteky/images/tecnicos/${item.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                />
            </ListItemAvatar>
            <ListItemText
                primary={<Typography variant='h5' mb={.5}>{item.name}</Typography>}
                secondary={item.email}
            />
            <Button
                size={"small"}
                variant={"contained"}
                color={"success"}
                disableElevation
                onClick={() => history.push(`usuarios/tecnicos/editar/${item.id}`)}
                sx={{
                    minWidth: 78,
                    textTransform: 'none',
                    p: theme => theme.spacing(.5, 1.5)
                }}
            >
                {item.status}
            </Button>
        </ListItem>

    )
};
/* Todo item, handleFollowToggle prop define */
export default ConnectionItem;
