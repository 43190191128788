import React from 'react';
import {Card, CardContent, Backdrop, CircularProgress, TextField, Typography, Alert} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import { useState } from 'react';
import api from "../../services/config";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from 'formik';

const Configurações = () => {
    const [loading, setLoading] = useState(false);
    const [confId, setConfId] = useState();

    const {auth} = useSelector(state => state);

    const [alertParams, setAlertParams] = useState({
        type: "",
        message: "",
        show: false
    });

    const validationSchema = Yup.object().shape({
        raio_km: Yup.number().required("Campo obrigatório"),
        segundos: Yup.number().required("Campo obrigatório"),
        bateria: Yup.number().required("Campo obrigatório"),
        intervalo_reabrir_ticket_auto: Yup.number().required("Campo obrigatório"),
        intervalo_coletar_localizacao: Yup.number().required("Campo obrigatório"),
    });

    const formik = useFormik({
        initialValues: {
            raio_km: 0,
            segundos: 0,
            bateria: 0,
            intervalo_reabrir_ticket_auto: 0,
            intervalo_coletar_localizacao: 0
        },
        validationSchema,
        onSubmit: updateConfiguracao
    })

    async function fetchConfiguracoes() {
        setLoading(true);
        try {
            const {data} = await api.get("/api/v2/dashboard/configuracao", {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            setConfId(data.id);

            formik.setFieldValue("raio_km", data.raio_km);
            formik.setFieldValue("segundos", data.segundos);
            formik.setFieldValue("intervalo_reabrir_ticket_auto", data.intervalo_reabrir_ticket_auto);
            formik.setFieldValue("intervalo_coletar_localizacao", data.intervalo_coletar_localizacao);
            formik.setFieldValue("bateria", data.bateria);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    async function updateConfiguracao(dt) {
        setLoading(true);
        try {
            await api.put(`api/v2/dashboard/configuracao/${confId}`, dt, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            setAlertParams({
                type: "success",
                message: "Configurações atualizadas com sucesso",
                show: true
            });
        } catch (error) {
            console.log("ERROR UPDATE CONFIGURATIONS > > ", error);
            setAlertParams({
                type: "error",
                message: "Erro ao atualizar configurações.",
                show: true
            });
        }

        setTimeout(() => {
            setAlertParams({
                ...alertParams,
                show: false
            });
        }, 5000);

        setLoading(false);
    }

    React.useEffect(() => {
        fetchConfiguracoes();
    }, []);

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h1" mb={3}>Configurações do Socket</Typography>
            <Card sx={{mb: 3.5}}>
                {
                    alertParams.show && ( 
                        <Alert
                            sx={{ m: 2 }}
                            variant="filled"
                            severity={alertParams.type}
                            onClose={() => {
                                setAlertParams({
                                    ...alertParams,
                                    show: false
                                });
                            }}
                        >
                            {alertParams.message}
                        </Alert>
                    )
                }
                <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                    <CardContent>
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{
                                mx: -1,

                                '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                    p: 1,
                                    mb: 2,
                                    width: {xs: '100%', sm: '100%'}
                                },

                                '& .MuiFormControl-root.MuiFormControl-fluid': {
                                    width: '100%'
                                }
                            }}
                            autoComplete="off"
                        >
                            <FormControl>
                                <TextField
                                    fullWidth
                                    id="raio_km"
                                    type="number"
                                    label="Raio de Busca (km)"
                                    value={formik.values.raio_km}
                                    onChange={formik.handleChange}
                                    error={formik.touched.raio_km && Boolean(formik.errors.raio_km)}
                                    helperText={formik.touched.raio_km && formik.errors.raio_km}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    id="segundos"
                                    type="number"
                                    label="Intervalo para aumento do raio de busca (segundos)"
                                    value={formik.values.segundos}
                                    onChange={formik.handleChange}
                                    error={formik.touched.segundos && Boolean(formik.errors.segundos)}
                                    helperText={formik.touched.segundos && formik.errors.segundos}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    id="intervalo_reabrir_ticket_auto"
                                    type="number"
                                    label="Intervalo reenvio de tickets automatico (segundos)"
                                    value={formik.values.intervalo_reabrir_ticket_auto}
                                    onChange={formik.handleChange}
                                    error={formik.touched.intervalo_reabrir_ticket_auto && Boolean(formik.errors.intervalo_reabrir_ticket_auto)}
                                    helperText={formik.touched.intervalo_reabrir_ticket_auto && formik.errors.intervalo_reabrir_ticket_auto}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="bateria"
                                    label="Nível mínimo de bateria para receber chamados (porcentagem)"
                                    value={formik.values.bateria}
                                    onChange={formik.handleChange}
                                    error={formik.touched.bateria && Boolean(formik.errors.bateria)}
                                    helperText={formik.touched.bateria && formik.errors.bateria}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth
                                    type="number"
                                    id="intervalo_coletar_localizacao"
                                    label="Intervalo de coleta de localização (minutos)"
                                    value={formik.values.intervalo_coletar_localizacao}
                                    onChange={formik.handleChange}
                                    error={formik.touched.intervalo_coletar_localizacao && Boolean(formik.errors.intervalo_coletar_localizacao)}
                                    helperText={formik.touched.intervalo_coletar_localizacao && formik.errors.intervalo_coletar_localizacao}
                                />
                            </FormControl>

                            <Div sx={{mx: 1}}>
                                <Button
                                    color="success"
                                    variant={"contained"}
                                    type="submit"
                                >
                                    Atualizar
                                </Button>
                            </Div>
                        </Box>
                    </CardContent>
                </Div>
            </Card>
        </React.Fragment>
    );
};

export default Configurações;
