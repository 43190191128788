import React, { useEffect, useState } from 'react';
import {useMutation} from "react-query";
import Div from "@jumbo/shared/Div";
import {Button, CircularProgress, List, ListItem, ListItemButton, ListItemText, Paper, Popover, Stack, TextField, Typography} from "@mui/material";
import useChatApp from "../../hooks/useChatApp";
import {chatService} from "../../../../services/chat-services";
import { socket } from 'app/services/socket';
import { Close, FileOpen, Menu, Send } from '@mui/icons-material';
import api from 'app/services/config';
import { useSelector } from "react-redux";

const ActiveConversationFooter = () => {
    const {
        activeConversation,
        activeConversationRef,
        favoriteConversationsListRef,
        recentConversationsListRef,
        contactConversationsListRef,
        setMessages
    } = useChatApp();

    const { auth } = useSelector(state => state);

    const [message, setMessage] = React.useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const addMessageMutation = useMutation(chatService.addConversationMessage, {
        onSuccess: () => {
            activeConversationRef?.current?.refresh();
            favoriteConversationsListRef?.current?.refresh();
            recentConversationsListRef?.current?.refresh();
            contactConversationsListRef?.current?.refresh();
        }
    });
    
    const onSendMessage = (event) => {
        const message = event.target.value.trim();
        if (event.key === 'Enter' && message) {
            const messageObject = {
                room: activeConversation.room,
                ticket: '987',
                message,
                type: 'SAC',
                read: false,
                author: 'Atendente SAC',
                author_id: 1,
                content_type: 'text'
            };
            //setMessages(prev => [...prev, messageObject]);
            socket.emit('sendMessage', messageObject);
            /* addMessageMutation.mutate({conversationID: activeConversation.id, message: message}) */
            setMessage('');
        }
    };

    useEffect(() => {
        if(socket) {
            socket.on("receivedMessage", msg => {
                if(msg.author_id == auth.data.user.id) return;
                setMessages(prev => [...prev, msg]);
            });
        }
    }, [socket]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function selectFileToSend(type) {
        handleClose();
        const input = document.getElementById("file-chat");
        
        input.accept = type;

        input.click();
    }
    
    React.useEffect(() => {
        if(!file) {
            const input = document.getElementById("file-chat");
            input.value = null;

        }
    }, [file]);

    async function uploadFile() {
        setLoading(true);
        try {
            const dt = new FormData();
            dt.append("select_file", file);
            const { data } = await api.post("/v1/ajaxfileupload", dt);

            const messageObject = {
                room: activeConversation.room,
                ticket: '',
                message: data.file,
                type: 'SAC',
                read: false,
                author: 'Atendente SAC',
                author_id: 1,
            };

            switch (data.extension.toLowerCase()) {
                case 'jpeg':
                    messageObject.content_type = 'imgfile';
                    break;
                case 'jpg':
                    messageObject.content_type = 'imgfile';
                    break;
                case 'png':
                    messageObject.content_type = 'imgfile';
                    break;
                case 'mp4':
                    messageObject.content_type = 'video/mp4';
                    break;
                default:
                    messageObject.content_type = 'textfile';
                    break;
            }

            //setMessages(prev => [...prev, messageObject]);
            socket.emit('sendMessage', messageObject);
            setFile(null);
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setLoading(false);
    }

    return (
        <Div
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: theme => theme.spacing(2, 3),
                borderTop: 1,
                borderTopColor: 'divider',
                bgcolor: theme => theme.palette.action.hover,
                position: "relative"
            }}
        >
            {
                file && (
                    <Div sx={{
                        position: "absolute",
                        bottom: 0,
                        ml: "-25px",
                        display: "flex",
                        width: "100%",
                        zIndex: 9999999
                    }}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: theme => theme.spacing(1.5, 2),
                                bgcolor: 'primary.main',
                                color: 'common.white',
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Stack
                                sx={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10
                                }}
                            >
                                <Button
                                    variant='contained'
                                    color='info'
                                    onClick={() => setFile(null)}
                                >
                                    <Close />
                                </Button>

                                <Button
                                    variant='contained'
                                    color='warning'
                                    sx={{
                                        mt: 1
                                    }}
                                    onClick={uploadFile}
                                >
                                    <Send />
                                </Button>
                            </Stack>
                            
                            <Div
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {
                                    loading && (
                                        <Div
                                            style={{
                                                position: "absolute",
                                                backgroundColor: "rgba(0,0,0,0.5)",
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: 10,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Div>
                                    )
                                }

                                {
                                    (file.type.includes("image")) && (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            style={{
                                                height: 350,
                                                borderRadius: 10
                                            }}
                                        />
                                    )
                                }
                                
                                {
                                    (file.type.includes("video")) && (
                                        <video
                                            src={URL.createObjectURL(file)}
                                            style={{
                                                height: 350,
                                                borderRadius: 10
                                            }}
                                            controls
                                        />
                                    )
                                }

                                {
                                    (file.type.includes("application")) && (
                                        <Div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <FileOpen color='#FFF' style={{ width: 70, height: 70, marginBottom: 2 }} />
                                            <Typography variant='body'>{file.name}</Typography>
                                        </Div>
                                    )
                                }
                            </Div>
                        </Paper>
                    </Div>
                )
            }

            <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                size={'small'}
                placeholder={"Digite a mensagem...."}
                onKeyPress={onSendMessage}
                fullWidth
                sx={{
                    backgroundColor: theme => theme.palette.background.paper
                }}
            />

            <input
                id='file-chat'
                type="file"
                style={{ display: "none" }}
                onChange={e => setFile(e.target.files[0])}
            />

            <Button
                aria-describedby={id}
                variant="outlined"
                color="success"
                onClick={handleClick}
                style={{
                    marginLeft: 10
                }}
            >
                <Menu />
            </Button>
            
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: "bottom"
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => selectFileToSend("image/*")}>
                            <ListItemText>Imagem</ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => selectFileToSend("video/*")}>
                            <ListItemText>Vídeo</ListItemText>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => selectFileToSend("file/*")}>
                            <ListItemText>Arquivo</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
        </Div>
    );
};

export default ActiveConversationFooter;
