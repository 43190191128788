import React from 'react';
import JumboAppContext from "@jumbo/components/JumboApp/JumboAppContext";
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/redux/store';
import { setRelatorio } from 'app/redux/modules/settings/action';

const JumboApp = (props) => {
    const [activeLayout, setActiveLayout] = React.useState(props.activeLayout);
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()

    const appContextValue = React.useMemo(() => {
        return {
            activeLayout,
            setActiveLayout,
        }
    }, [activeLayout, setActiveLayout]);

    React.useEffect(() => {
        //console.log('History ----->',history)
        dispatch(setRelatorio());
        if (history.location.pathname !== '/login'&& history.location.pathname !== '/recupera-senha') {
            if (!auth || auth.token == '') {
                history.push('/login')
            }
        }

    }, [])

    return (
        <JumboAppContext.Provider value={appContextValue}>
            {props?.children}
        </JumboAppContext.Provider>
    );
};

export default JumboApp;