import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Div from "@jumbo/shared/Div";
import { Stack } from '@mui/system';
import { Button, Divider } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: 5,
    minWidth: "20%",
    boxShadow: 24,
    /* p: 4, */
};

const ModalAlerta = ({ open, setOpen, message, buttons }) => {
    const handleClose = () => setOpen(false);

    return (
        <Div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <Typography
                            variant="h2"
                            sx={{
                                m: 2,
                                textAlign: "center"
                            }}
                        >
                                {message}
                        </Typography>

                        {
                            buttons.length > 0 && (
                                <>
                                    <Divider />

                                    <Stack
                                        direction="row"
                                        justifyContent={buttons.length == 1 ? "center" : "space-between"}
                                        sx={{
                                            m: 2
                                        }}
                                    >
                                        {
                                            buttons.map(button => (
                                                <Button {...button.props}>
                                                    {button.text}
                                                </Button>
                                            ))
                                        }
                                    </Stack>
                                </>
                            )
                        }
                    </Div>
                </Fade>
            </Modal>
        </Div>
    );
};
export default ModalAlerta;