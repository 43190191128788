import React, { useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Div from "@jumbo/shared/Div";
import FormCliente from "./components/Forms/FormCliente";
import FormTecnico from "./components/Forms/FormTecnico";
import FormUsuario from "./components/Forms/FormUsuario";
import api from "../../services/config";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const CadastroUsuario = () => {
    const { type, id } = useParams();
    const { auth } = useSelector(state => state);

    const [user, setUser] = React.useState(null);

    const [loading, setLoading] = useState(false);

    async function fetchUsuario() {
        setLoading(true);
        try {
            const {data} = await api.get(`/api/v2/dashboard/${type == "administradores" ? "usuarios" : type}/${id}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            console.log("DATA USER > > ", data);
            setUser(type == "clientes" ? data.user : data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if(type != "administrador" && id) {
            fetchUsuario();
        }
    }, [id]);

    const pageLabel = () => {
        switch(type) {
            case "clientes":
                return "Cliente";
            case "tecnicos":
                return "Técnico";
            case "administradores":
                return "Administradores";
        }
    }

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Typography variant="h1" mb={3}>Cadastro de {pageLabel()}</Typography>
            <Card sx={{display: 'flex', mb: 3.5}}>
                <Div sx={{display: 'flex', flexDirection: 'column', flex: '1'}}>
                    <CardContent>
                        <Box
                             sx={{
                                 mx: -1,

                                 '& .MuiFormControl-root:not(.MuiTextField-root)': {
                                     p: 1,
                                     mb: 2,
                                     width: {xs: '100%', sm: '50%'}
                                 },

                                 '& .MuiFormControl-root.MuiFormControl-fluid': {
                                     width: '100%'
                                 },
                             }}
                             autoComplete="off"
                        >
                            {
                                type == "clientes" && <FormCliente user={user} setLoading={setLoading} />
                            }

                            {
                                type == "tecnicos" && <FormTecnico user={user} setLoading={setLoading} />
                            }

                            {
                                type == "administradores" && <FormUsuario user={user} setLoading={setLoading} />
                            }
                        </Box>
                    </CardContent>
                </Div>
            </Card>
        </React.Fragment>
    );
}

export default CadastroUsuario;