import React from 'react';
import ActiveConversationChat from "./ActiveConversationChat";
import useChatApp from "../../../hooks/useChatApp";
import moment from 'moment';

const chatGroupedByDate = (array, key) =>
    Object.entries(
        array.reduce((result, {[key]: k, ...rest}) => {
            (result[moment(k).format("DD/MM/YYYY HH:mm")] = result[moment(k).format("DD/MM/YYYY HH:mm")] || []).push(rest);
            return result;
        }, {})
    ).map(([sent_date, messages]) => ({
        sent_date,
        messages
    }));

const ConversationChatGroupByDate = () => {
    const {activeConversation, messages} = useChatApp();

    const conversationMessages = React.useMemo(() => {
        if(messages)
            return chatGroupedByDate(messages, 'created');

        return [];
    }, [messages]);
    
    return (
        <React.Fragment>
            {
                conversationMessages?.map((messagesGroupByDate, index) => (
                    <ActiveConversationChat key={index} conversation={messagesGroupByDate}/>
                ))
            }
        </React.Fragment>
    );
};

export default ConversationChatGroupByDate;
