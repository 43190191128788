import React, { useState } from "react";
import { Alert, Avatar, CardHeader, Divider, MenuItem, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Div from "@jumbo/shared/Div";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "app/redux/store";
import api from "../../../../services/config";
import { useSelector } from "react-redux";

const FormUsuario = ({ user, setLoading }) => {
    const {auth} = useSelector(state => state);
    const [photo, setPhoto] = useState(null);
    const [alertParams, setAlertParams] = useState({
        type: "",
        message: "",
        show: false
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Campo obrigatório"),
        email: Yup.string().email("Digite um email válido").required("Campo obrigatório"),
        password: Yup.string().optional(),
        role: Yup.string().required("Campo obrigatório"),
        status: Yup.string().required("Campo obrigatório")
    })

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
            role: "",
            status: "" 
        },
        validationSchema,
        onSubmit: submitForm
    })

    async function submitForm(dataUsuario) {
        if(!user && !dataUsuario.password) {
            formik.setFieldError("password", "Campo obrigatório");
            return;
        }

        if(photo)
            dataUsuario['foto'] = photo;

        if(user && dataUsuario.password == "")
            delete dataUsuario.password;

        const formData = new FormData();
        
        Object.keys(dataUsuario).map(key => {
            if(dataUsuario[key]) {
                formData.append(key, dataUsuario[key]);
            }
        });

        if(user)
            formData.append("_method", "PUT");

        setLoading(true);
        try {
            const {data} = await api.post(`api/v2/dashboard/usuarios/${user ? user.id : ""}`, formData, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            console.log("DATA > > > ", data);

            setAlertParams({
                type: "success",
                message: `Usuário ${user ? "atualizado" : "criado"} com sucesso`,
                show: true
            });
        } catch (error) {
            console.log("ERROR CREATE ADMIN > > ", error);
            setAlertParams({
                type: "error",
                message: `Ocorreu um erro ao ${user ? "atualizar" : "criar"} o usuário.`,
                show: true
            });
        }
        window.scrollTo(0, 0);
        setLoading(false);
    }

    React.useEffect(() => {
        if(user) {
            formik.setFieldValue("name", user.name);
            formik.setFieldValue("email", user.email);
            formik.setFieldValue("role", user.roles[0].name);
            formik.setFieldValue("status", user.status);
        }
    }, [user]);

    async function desativaUsuario() {
        setLoading(true);
        try {
            const { data } = await api.delete(`api/v2/dashboard/usuarios/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            console.log(data);
            setAlertParams({
                type: "success",
                message: `Usuário desativado com sucesso`,
                show: true
            });
            window.location.reload();
        } catch (error) {
            console.log("ERROR > > ", error);
            setAlertParams({
                type: "error",
                message: `Ocorreu um erro ao desativar usuário`,
                show: true
            });
        }
        window.scrollTo(0, 0);
        setLoading(false);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {
                alertParams.show && ( 
                    <Alert
                        sx={{ m: 2 }}
                        variant="filled"
                        severity={alertParams.type}
                        onClose={() => {
                            setAlertParams({
                                ...alertParams,
                                show: false
                            });
                        }}
                    >
                        {alertParams.message}
                    </Alert>
                )
            }

            <CardHeader title="Dados do Usuário" sx={{ mt: -1, ml: -1 }} />
                <Divider sx={{ mb: 2, mt: -1 }} />

                <Div
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Div
                        sx={{
                            mb: 3,
                            display: "flex",
                            justifyContent: "center"
                        }}
                        onClick={() => {
                            const input = document.getElementById("photo-upload");
                            input.click();
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 200,
                                height: 200,
                                bgcolor: "#cecece",
                                cursor: "pointer"
                            }}
                            src={photo ? URL.createObjectURL(photo) : `https://storage.googleapis.com/uteky/images/tecnicos/${user ? user.id : ""}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                        />
                    </Div>
                </Div>

                <input
                    type="file"
                    id="photo-upload"
                    accept="image/*"
                    onChange={e => {
                        setPhoto(e.target.files[0]);
                    }}
                    style={{
                        display: "none"
                    }}
                />

                <Divider sx={{ mb: 2, mt: -1 }} />
               
                <FormControl>
                    <TextField
                        fullWidth
                        id="name"
                        label="Nome"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        id="password"
                        type="password"
                        label="Senha"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        id="role"
                        name="role"
                        select
                        label="Permissão"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        error={formik.touched.role && Boolean(formik.errors.role)}
                        helperText={formik.touched.role && formik.errors.role}
                    >
                        <MenuItem value="Administrador">Administrador</MenuItem>
                        <MenuItem value="Abastecedor">Abastecedor</MenuItem>
                        <MenuItem value="SAC">SAC</MenuItem>
                    </TextField>
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        select
                        id="status"
                        name="status"
                        label="Status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        helperText={formik.touched.status && formik.errors.status}
                    >
                        <MenuItem value="Ativo">Ativo</MenuItem>
                        <MenuItem value="Desativado">Desativado</MenuItem>
                    </TextField>
                </FormControl>
                
                <Div sx={{mx: 1}}>
                    <Button 
                        variant={"contained"}
                        color="inherit"
                        type="reset"
                        sx={{ mr: 1 }}
                        onClick={() => history.goBack()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant={"contained"}
                        type="submit"
                        sx={{ mr: 1 }}
                    >
                        Salvar
                    </Button>
                    {
                        window.location.href.includes("/editar") && (
                            <Button 
                                variant={"contained"}
                                color="error"
                                type="reset"
                                onClick={desativaUsuario}
                            >
                                Deletar
                            </Button>
                        )
                    }
                </Div>
        </form>
    );
}

export default FormUsuario;