import React from 'react';
import UserItem from "./UserItem";

const UsersList = ({ data, type }) => {
    return (
        <React.Fragment>
            {
                data.map((user, index) => (
                    <UserItem user={user} type={type} key={index}/>
                ))
            }
        </React.Fragment>
    );
};

export default UsersList;
