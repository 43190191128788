import React from 'react';
import ChatAppProvider from "./ChatAppProvider";
import ChatAppLayout from "./ChatAppLayout";
import { useEffect } from 'react';
import { socket } from 'app/services/socket';

const ChatApp = () => {
    useEffect(() => {
        socket.on("connect", () => {
            socket.emit('conn-event', {
                id: 0,
                name: 'SAC',
                type: 'sac',
                status:'sac-online'
            });
        });

        socket.on("connect_error", reason => {
            console.log("CONNECT ERROR > ", reason);
        });

        socket.connect();
    }, []);

    return (
        <ChatAppProvider>
            <ChatAppLayout />
        </ChatAppProvider>
    );
};

export default ChatApp;
