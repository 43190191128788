import React from 'react';
import Avatar from "@mui/material/Avatar";
import {Card, Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { history } from 'app/redux/store';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const UserItem = ({user}) => {
    function handleVerDetalhes() {
        history.push(`/tecnicos/fila/${user.id}`);
    }

    return (
        <Card sx={{mb: 1}}>
            <Grid container alignItems="center" spacing={1} sx={{p: theme => theme.spacing(2, 1)}}>
                <Grid
                    item
                    xs={12}
                    xl={1}
                >
                    <Item
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar
                                sx={{
                                    width: 56,
                                    height: 56
                                }}
                                alt={user.name}
                                src={`https://storage.googleapis.com/uteky/images/tecnicos/${user.id || user.user_id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                            />
                    </Item>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    xl={2}
                >
                    <Item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xl: "flex-start", xs: "center" },
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Nome
                        </Typography>
                        <Typography variant={"body1"}>{user.name}</Typography>
                    </Item>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    xl={4}
                >
                    <Item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xl: "flex-start", xs: "center" },
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            E-mail
                        </Typography>
                        <Typography variant={"body1"}>{user.email}</Typography>
                    </Item>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    xl={2}
                >
                    <Item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xl: "flex-start", xs: "center" },
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Telefone
                        </Typography>
                        <Typography variant={"body1"}>{user.cel}</Typography>
                    </Item>
                </Grid>

                <Grid
                    item
                    xs={12}
                    xl={3}
                >
                    <Item
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: { xl: "flex-end", xs: "center" },
                        }}
                    >
                        <Button
                            onClick={handleVerDetalhes}
                            variant="outlined"
                            color="success"
                        >
                            Ver fila de tickets
                        </Button>
                    </Item>
                </Grid>
            </Grid>
        </Card>
    );
};

export default UserItem;
