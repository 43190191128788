import React, { useEffect } from 'react';
import {Card, Backdrop, CircularProgress, Typography, Stack, FormControl, InputLabel, Select, MenuItem, Button, Avatar, List, ListItem, ListItemText, Divider, CardHeader, Box, Tabs, Tab} from "@mui/material";
import { useState } from 'react';
import Div from '@jumbo/shared/Div/Div';
import api from "../../services/config";
import MapPopupInfo from './components/MapPopupInfo';
import Lottie from "lottie-react";
import mapsAnimation from "../../assets/maps.json";
import searchingAnimation from "../../assets/searching.json";
import ModalAlerta from 'app/components/ModalAlert';
import ModalTecnicos from './components/ModalTecnicos';
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import Geocode from "react-geocode";
import { calculeDistance, formateDistanceToString } from 'app/utils/distance';
import ModalPermissao from './components/ModalPermissao';
import { useSelector } from 'react-redux';
import ModalRevogarPermissao from './components/ModalRevogarPermissao';

const Localizacao = () => {
    Geocode.setApiKey("AIzaSyDA5mGn5b68YB_gD1vWyKehLz89U00vOtM");

    const {auth} = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [user, setUser] = useState(null);
    const [selectUser, setSelectUser] = useState(false);
    const [period, setPeriod] = useState("");
    const [historico, setHistorico] = useState(null);
    const [historicoTickets, setHistoricoTickets] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [distances, setDistances] = useState([]);
    const [showPermissions, setShowPermissions] = useState(false);
    const [showRevokePermissions, setShowRevokePermissions] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function getHistoricoTecnico(user, dates) {
        setSelectUser(false);
        setHistorico(null);
        setDistances([]);
        setSearching(true);
        setUser(user);
        setPeriod(`${moment(dates[0]).format("DD/MM/YYYY")} à ${moment(dates[1]).format("DD/MM/YYYY")}`);

        try {
            const { data } = await api.get(`api/v2/historico-localizacao/${user.id}`, { params: { dates: dates.join(",") , auth_id: auth.data.user.id } });

            if(!data.historico || data.historico.logs.length == 0)
                setShowAlert(true);
            else {
                const logsQuantity = data.historico.logs.length;

                for (let i = 0; i < logsQuantity - 1; i++) {
                    const log1 = data.historico.logs[i];
                    const log2 = data.historico.logs[i + 1];

                    setDistances(prev => [...prev, calculeDistance(log1, log2)]);
                }

                setHistorico(data.historico);
                setHistoricoTickets(data.tickets);
            }
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setSearching(false);
    }

    /* useEffect(() => {
        fetchTecnicosListagem();
    }, []); */

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box  sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ModalAlerta
                open={showAlert}
                setOpen={setShowAlert}
                message="Esse técnico não possui histórico de localização"
                buttons={[
                    {
                        text: "OK",
                        props: {
                            variant: "contained",
                            color: "success",
                            onClick: () => setShowAlert(false)
                        }
                    }
                ]}
            />

            <ModalTecnicos
                open={selectUser}
                setOpen={setSelectUser}
                handleGetHistorico={getHistoricoTecnico}
            />

            <ModalPermissao
                open={showPermissions}
                setOpen={setShowPermissions}
            />

            <ModalRevogarPermissao
                open={showRevokePermissions}
                setOpen={setShowRevokePermissions}
            />

            <Typography variant="h1" mb={3}>Localização de Técnico</Typography>
            <Card sx={{mb: 3.5, position: "relative"}}>
                <Stack
                    sx={{
                        position: "absolute",
                        zIndex: 99,
                        right: 20,
                        top: 20
                    }}
                    direction="row"
                >
                    {
                        auth && auth.data && auth.data.user.roles[0].name == "Administrador Level 2" && (
                            <>
                                <Button
                                    disabled={searching}
                                    color='info'
                                    sx={{ mr: 2 }}
                                    variant='contained'
                                    onClick={() => setShowPermissions(true)}
                                >
                                    Permissões
                                </Button>
                            </>
                        )
                    }
                    <Button
                        disabled={searching}
                        color='success'
                        variant='contained'
                        onClick={() => setSelectUser(true)}
                    >
                        <SearchIcon />
                    </Button>
                </Stack>

                {
                    searching ? (
                        <Lottie style={{ height: 550 }} animationData={searchingAnimation} />
                    ) : (
                        <>
                            {
                                historico ? (
                                    <>
                                        <Stack
                                            flexDirection="row"
                                            alignItems="center"
                                            sx={{
                                                p: 5,
                                                pb: 0,
                                                bgcolor: "#f5f5f5"
                                            }}
                                        >
                                            <Avatar
                                                alt={user.name}
                                                src={`https://storage.googleapis.com/uteky/images/tecnicos/${user.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                                                sx={{width: 80, height: 80, mr: 2}}
                                            />
                                            <Typography sx={{ fontSize: 16 }}>{user.name} - {period} ({formateDistanceToString(distances.reduce((acc, distance) => acc + distance, 0))} percorridos no total)</Typography>
                                        </Stack>

                                        <Stack direction="row">
                                            <Div sx={{ bgcolor: "#f5f5f5", display: "flex", flex: 1, flexDirection: "column" }}>
                                                <MapPopupInfo historico={historico} tickets={historicoTickets} />
                                            </Div>

                                            <Div sx={{ bgcolor: "#f5f5f5", display: "flex", flex: 1, flexDirection: "column", pt: 3, maxWidth: "600px" }}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                        <Tab label="Histórico de Localização" />
                                                        <Tab label="Histórico de Tickets" />
                                                    </Tabs>
                                                </Box>

                                                {/* <CardHeader title="Histórico de Localização" /> */}

                                                <Div hidden={value != 0}>
                                                    <List
                                                        disablePadding
                                                        sx={{ width: "100%", overflowY: "scroll", maxHeight: "550px" }}
                                                    >
                                                        {
                                                            historico.logs.map((loc, index) => (
                                                                <>
                                                                    <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1.25, 3)}}>
                                                                        <ListItemText
                                                                            primary={`${index + 1} `}
                                                                            secondary={` - ${loc.endereco}`}
                                                                            sx={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                                alignItems: "center"
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    
                                                                    {
                                                                        index < distances.length && (
                                                                            <Div
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center"
                                                                                }}
                                                                            >
                                                                                <Div
                                                                                    sx={{
                                                                                        border: "1px solid #CECECE",
                                                                                        height: 10,
                                                                                        p: 0,
                                                                                        width: 0
                                                                                    }}
                                                                                />

                                                                                <Typography
                                                                                    sx={{
                                                                                        color: "#8595A6",
                                                                                        fontSize: "12px"
                                                                                    }}
                                                                                >
                                                                                    {formateDistanceToString(distances[index])}
                                                                                </Typography>

                                                                                <Div
                                                                                    sx={{
                                                                                        border: "1px solid #CECECE",
                                                                                        height: 10,
                                                                                        p: 0,
                                                                                        m: 0,
                                                                                        width: 0
                                                                                    }}
                                                                                />
                                                                            </Div>
                                                                        )
                                                                    }

                                                                    {/* <Divider component="li"/> */}
                                                                </>
                                                            ))
                                                        }
                                                    </List>
                                                </Div>

                                                <Div hidden={value != 1}>
                                                    <List
                                                        disablePadding
                                                        sx={{ width: "100%", overflowY: "scroll", maxHeight: "550px" }}
                                                    >
                                                        {
                                                            historicoTickets.map((ticket, index) => (
                                                                <>
                                                                    <Div sx={{ display: "flex", flexDirection: "row" }}>
                                                                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1, 2)}}>
                                                                            <ListItemText
                                                                                primary={`OS`}
                                                                                secondary={`#${ticket.os}`}
                                                                            />
                                                                        </ListItem>
                                                                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1, 2)}}>
                                                                            <ListItemText
                                                                                primary={`Encerrado em`}
                                                                                secondary={`${moment(ticket.data_de_resolucao).format("DD/MM/YYYY HH:mm")}`}
                                                                            />
                                                                        </ListItem>
                                                                        <ListItem alignItems="flex-start" sx={{p: theme => theme.spacing(1, 2)}}>
                                                                            <ListItemText
                                                                                primary={`Endereço`}
                                                                                secondary={`${ticket.endereco}`}
                                                                            />
                                                                        </ListItem>
                                                                    </Div>

                                                                    <Divider component="li"/>
                                                                </>
                                                            ))
                                                        }
                                                    </List>
                                                </Div>
                                            </Div>
                                        </Stack>
                                    </>
                                ) : (
                                    <>
                                        <Lottie style={{ height: 550 }} animationData={mapsAnimation} />
                                        <Typography textAlign="center" sx={{ mb: 5, mt: -2 }}>Selecione um técnico para visualizar seu log de localização!</Typography>
                                    </>
                                )
                            }
                        </>
                    )
                }
            </Card>
        </React.Fragment>
    );
};

export default Localizacao;
