import Login2 from "app/pages/AuthPage";

import React from "react";
import MiscDashboard from "app/pages/dashboards/misc/MiscDashboard";
import ChamadosTecnicos from "app/pages/ChamadosTecnicos";
import DetalhesChamados from "app/pages/DetalhesChamados";
import FilaChamados from "app/pages/FilaChamados";
import Usuarios, { CadastroUsuario } from "app/pages/Usuarios";
import Configuracoes from "app/pages/Configuracoes";
import Chat from "app/pages/Chat";
import FilaChamadosDetalhes from "app/pages/FilaChamadosDetalhes";
import QRCode from "app/pages/QRCode";
import RecuperarSenha from "app/pages/RecuperarSenha";
import Localizacao from "app/pages/Localizacao";

const routes = [
     {
          path: "recupera-senha",
          element: <RecuperarSenha />,
     },
     {
          path: "/",
          element: <MiscDashboard />,
     },
     {
          path: "/dashboard",
          element: <MiscDashboard />,
     },
     {
          path: "login",
          element: <Login2 />,
     },
     {
          path: "chamados/:type",
          element: <ChamadosTecnicos />
     },
     {
          path: "chamados/detalhes/:id",
          element: <DetalhesChamados />
     },
     {
          path: "tecnicos/fila",
          element: <FilaChamados />
     },
     {
          path: "tecnicos/fila/:id",
          element: <FilaChamadosDetalhes />
     },
     {
          path: "usuarios/:type",
          element: <Usuarios />
     },
     {
          path: "usuarios/:type/cadastro",
          element: <CadastroUsuario />
     },
     {
          path: "usuarios/:type/editar/:id",
          element: <CadastroUsuario />
     },
     {
          path: "configuracao",   
          element: <Configuracoes />
     },
     {
          path: "chats",
          element: <Chat />
     },
     {
          path: "chats/:chatBy/:id",
          element: <Chat />
     },
     {
          path: "qrcode",
          element: <QRCode />
     },
     {
          path: "tecnicos/localizacao",
          element: <Localizacao />
     }
];

export default routes;