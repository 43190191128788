import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Div from "@jumbo/shared/Div";
import { Button, CardHeader, CircularProgress, Divider, FormControl, MenuItem, Pagination, Stack, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@mui/icons-material';
import JumboSearch from '@jumbo/components/JumboSearch';
import Item from './Item';
import api from 'app/services/config';
import ChamadosSkeleton from './Skeleton';
import { socketTicket } from 'app/services/socket';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
    overflowY: "scroll"
};

const ModalAtribuir = ({ open, setOpen, userId, refresh }) => {
    const handleClose = () => setOpen(false);
    const { auth } = useSelector(state => state);

    const [pendingsTickets, setPendingsTickets] = React.useState([]);

    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [paramsOrder, setParams] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [inserting, setInserting] = React.useState(false);

    React.useLayoutEffect(() => {
        setTimeout(getPendingsTickets, loading ? 500 : 0);
    }, [page, paramsOrder]);

    React.useLayoutEffect(() => {
        setPage(1);
    }, []);

    async function getPendingsTickets() {
        setLoading(true);
        try {
            let params = {};

            if(paramsOrder.s) {
                params['s'] = paramsOrder.s;
            }

            const {data} = await api.get(`api/v2/dashboard/pending_tickets?page=${page}`, {
                params,
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            setPendingsTickets(data.data);
            setTotalPages(data.last_page);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    async function insereFila(ticket_id) {
        setInserting(true);
        try {
            const {data} = await api.post("api/v2/dashboard/fila", {
                ticket_id,
                user_id: userId
            }, {
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            socketTicket.emit("admin-assign", {
                tecnico_id: parseInt(userId),
                ticket: data.ticket,
            });

            setOpen(false);
            refresh();
        } catch (error) {
            console.log("ERROR > > ", error);
        }
        setInserting(false);
    }

    return (
        <Div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99 }}
                open={inserting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            mb={1}
                        >
                            <Typography id="transition-modal-title" variant="h4" component="h2">
                                Atribuir Chamado
                            </Typography>

                            <Button
                                color="error"
                                onClick={() => setOpen(false)}
                            >
                                <CloseOutlined />
                            </Button>
                        </Stack>
                        
                        <Divider sx={{ mb: 2 }} />

                        <Stack>
                            <Div
                                sx={{
                                    bgcolor: "#cecece",
                                    py: 2,
                                    px: 1,
                                    mb: 2,
                                    display: "flex",
                                    borderRadius: 2,
                                    justifyContent: "flex-end"
                                }}
                            >
                                <JumboSearch
                                    onChange={(e) => 
                                        setParams({
                                            ...paramsOrder,
                                            s: e
                                        })
                                    }
                                    placeholder="Filtrar Chamado"
                                />
                            </Div>

                            {
                                loading ? <ChamadosSkeleton /> : (
                                    <Stack>
                                        {
                                            pendingsTickets.map((ticket, i) => (
                                                <Item
                                                    ticket={ticket}
                                                    key={i.toString()}
                                                    handleAssign={insereFila}
                                                />
                                            ))
                                        }

                                        <Pagination
                                            count={totalPages}
                                            page={page}
                                            onChange={(event, value) => setPage(value)}
                                            shape="rounded"
                                            variant="outlined"
                                            color="secondary"
                                        />
                                    </Stack>
                                )
                            }
                        </Stack>
                    </Div>
                </Fade>
            </Modal>
        </Div>
    );
};
export default ModalAtribuir;