import React from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {Accordion, AccordionDetails, Avatar, Button, Card, Checkbox, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Div from "@jumbo/shared/Div";
import { history } from 'app/redux/store';
import moment from "moment";

const ProjectItem = ({item, data}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleClick = () => {
        history.push(`/chamados/detalhes/${item.id}`);
    }
    
    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    /* expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>} */
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    <Div sx={{flexShrink: 0, px: 1}}>
                        <Div
                            sx={{
                                width: 60,
                                height: 60,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Checkbox color="success" />
                        </Div>
                    </Div>
                    <Div sx={{flexShrink: 0, px: 1}}>
                        <Div
                            sx={{
                                width: 60,
                                height: 60,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#0DA594",
                                borderRadius: "50%"
                            }}
                        >
                            <Typography
                                variant={"h6"}
                                color="#FFF"
                                mb={0}
                            >
                                1
                            </Typography>
                        </Div>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '26%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Código
                        </Typography>
                        <Typography variant={"body1"}>Q31211345</Typography>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '26%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Status da impressão
                        </Typography>
                        <Typography variant={"body1"}>Impresso</Typography>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', lg: '26%'},
                            flexShrink: 0,
                            px: 1,
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            flex: {xs: '1', lg: '0 1 auto'},
                        }}
                    >
                        <Button variant="outlined" color="success">
                            Imprimir
                        </Button>
                    </Div>
                </AccordionSummary>
            </Accordion>
        </Card>
    );
};
/* Todo item prop define */
export default ProjectItem;
