import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import { useSelector } from "react-redux";
import { Pagination, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState } from 'react';
import UsersList from './components/UsersList';
import api from "../../services/config";
import FilaSkeleton from './Skeleton';
import JumboSearch from '@jumbo/components/JumboSearch';
import ModalNovoChamado from 'app/components/ModalNovoChamado';
import SortIcon from '@mui/icons-material/Sort';
import Div from '@jumbo/shared/Div';

const FilaChamados = () => {
    const { auth } = useSelector(state => state);
    const [loading, setLoading] = useState(false);

    const [filas, setFilas] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [novoChamado, setNovoChamado] = useState(false);

    const [sortBy, setSortBy] = useState("");
    const [order, setOrder] = useState("asc");
    const [paramsOrder, setParams] = useState({});
    const [continueFetching, setContinueFetching] = useState(true);

    function toggleOrder() {
        setOrder(order == "asc" ? "desc" : "asc");
    }

    const {setActiveLayout} = useJumboApp();
    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
    }, []);

    React.useLayoutEffect(() => {
        setTimeout(fetchFila, loading ? 500 : 0);
    }, [page, paramsOrder, order]);

    /* React.useEffect(() => {
        fetchFila();
    }, [page]); */

    async function fetchFila() {
        if(!continueFetching) return;

        setLoading(true);
        try {
            let params = {};

            console.log(paramsOrder);

            if(paramsOrder.s) {
                params['s'] = paramsOrder.s;
            }
            
            if(paramsOrder.orderBy) {
                params['orderBy'] = `${paramsOrder.orderBy},${order}`;
            }

            const { data } = await api.get(`/api/v2/dashboard/fila?page=${page}`, {
                params,
                headers: {
                    Authorization: `Bearer ${auth.token}`
                }
            });

            console.log("DATA FILA > > > ", data);
            setFilas(data.data);
            setTotalPages(data.last_page);
        } catch (error) {
            console.log(error);   
        }
        setLoading(false);

        setContinueFetching(true);
    }

    return (
        <>
            <ModalNovoChamado
                open={novoChamado}
                setOpen={setNovoChamado}
            />

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant={'h2'} mb={3}>Fila de chamados por técnico</Typography>

                <Stack direction="row">
                    <Div sx={{ mr: 3 }}>
                        <FormControl sx={{ mr: 1, minWidth: 150}} size="small">
                            <InputLabel id="demo-select-small">Ordenar por</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={paramsOrder.orderBy}
                                label="orderBy"
                                onChange={(event) => {
                                    setParams({
                                        ...paramsOrder,
                                        orderBy: event.target.value
                                    })
                                }}
                            >
                                <MenuItem value="">
                                    <em>Selecione...</em>
                                </MenuItem>
                                <MenuItem value="id">ID</MenuItem>
                                <MenuItem value="name">Nome</MenuItem>
                                <MenuItem value="email">E-mail</MenuItem>
                                <MenuItem value="cel">Telefone</MenuItem>
                            </Select>
                        </FormControl>
                        
                        <Button
                            variant='outlined'
                            onClick={toggleOrder}
                        >
                            <SortIcon sx={{ transform: `rotateX(${order == "asc" ? 0 : 180}deg)` }} />
                        </Button>
                    </Div>

                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setNovoChamado(true)}
                    >
                        Novo Chamado
                    </Button>
                    <JumboSearch
                        onChange={(e) => {
                            setContinueFetching(false);
                            setPage(1);
                            setContinueFetching(true);
                            setParams({
                                ...paramsOrder,
                                s: e
                            });
                        }}
                        placeholder="Filtrar técnicos"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            flex: 1,
                            ml: 2
                        }}
                    />
                </Stack>
            </Stack>

            {
                loading 
                ? (
                    <FilaSkeleton />  
                )
                : (
                    <>
                        <Stack style={{ marginTop: 10 }}>
                            <UsersList
                                data={filas}
                                type="todos"
                            />
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                shape="rounded"
                                variant="outlined"
                                color="secondary"
                            />
                        </Stack>
                    </>
                )
            }
        </>
    );
};

export default FilaChamados;