import React from 'react';
import Div from "@jumbo/shared/Div";
import {Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import { FileOpen } from '@mui/icons-material';

const SentMessageContent = ({message}) => {
    return (
        <Div
            sx={{
                display: 'flex',
                textAlign: 'right',
                alignItems: 'flex-start',
                flexDirection: 'row-reverse',
                mb: 2,
                px: 3,
            }}
        >
            <div className="Message-root">
                <div className="Message-item">
                    <Typography
                        variant={"body1"}
                        color={"text.secondary"}
                        fontSize={"smaller"}
                        mb={.5}
                    >
                        {message?.sent_at}
                    </Typography>
                    <Paper elevation={0} sx={{p: theme => theme.spacing(1.5, 2), bgcolor: theme => theme.palette.divider}}>
                        {
                            message.content_type == "text" && <Typography variant={"body1"}>{message?.message}</Typography>
                        }

                        {
                            message.content_type == "imgfile" && (
                                <a href={`https://storage.googleapis.com/uteky/chat/${message?.message}`} target="_blank">
                                    <img style={{ width: 500, height: 500, borderRadius: 10 }} src={`https://storage.googleapis.com/uteky/chat/${message?.message}`} />
                                </a>
                            )
                        }

                        {
                            message.content_type == "video/mp4" && (
                                <video
                                    style={{ width: 500, height: 500, borderRadius: 10 }}
                                    src={`https://storage.googleapis.com/uteky/chat/${message?.message}`}
                                    controls
                                />
                            )
                        }

                        {
                            message.content_type == "textfile" && (
                                <a
                                    href={`https://storage.googleapis.com/uteky/chat/${message?.message}`}
                                    target="_blank"
                                    style={{
                                        color: "#FFF",
                                    }}
                                >
                                    <FileOpen color='#FFF' style={{ width: 70, height: 70 }} />
                                </a>
                            )
                        }
                    </Paper>
                </div>
            </div>
        </Div>
    );
};

export default SentMessageContent;
