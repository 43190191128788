import React from 'react';
import { Backdrop, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from 'app/layouts/layouts';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import api from 'app/services/config';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { login } from 'app/redux/modules/auth/action';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { history } from 'app/redux/store';
import { useState } from 'react';

const Login2 = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setActiveLayout } = useJumboApp();
    const [loading, setLoading] = useState(false)

    async function handleAuth(dt) {
        setLoading(true);
        try {
            const { data } = await api.post("/api/v2/dashboard/session", dt);
            
            dispatch(login(data, data.access_token));
            history.push('/dashboard/')
            setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);

       //     const response = await api.post("auth/login", data);
           /*  const response = {"data" : {"status":"ok","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5teWJ3YXkuaG9tb2xvZy5yb2NrYXBwcy5jb20uYnIvdjEvYXV0aC9sb2dpbiIsImlhdCI6MTY4MTgzOTYwNywiZXhwIjo2ODY1ODM5NjA3LCJuYmYiOjE2ODE4Mzk2MDcsImp0aSI6ImxKWmhScHFvZG1qTmdVc0YiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.CGflTy8VwUSP9FALgiP81WBdDcKfGRo6835YwoE79Nw","expires_in":5184000000,"user":{"id":1,"value":1,"fid":"USR-1","name":"Mybway","email":"admin@mybway.com.br","hair_color":"","hair_type":"","birth_date":"","photo":"https:\/\/s3.docker.rockapps.com.br\/projects-homolog\/rodadas-homolog\/user\/1\/20221104031600-97aa81af-b74e-40d1-ace6-b239cae0a0ec","document":"","education":"","occupation":"","service_preference":"","workplace":"","city":"Port Gaylordmouth","description":"Usu\u00e1rio Administrador da MyBway","payment_document":"","gender":"","admin_comments":"","address_street":"3337 Kihn Ports Suite 391","address_number":"3137","address_complement":null,"address_district":"Corkeryhaven","address_zipcode":"22621140","address_city":"Port Gaylordmouth","address_state":"Texas","mobile":"","telephone":"","verified":0,"RefferUUID":"VendaDireta","suspended":0,"profile":{"social_linkedin":null,"social_facebook":null,"social_instagram":null,"social_twitter":null,"social_tiktok":null,"skills":[],"experiences":[],"preferences":[]},"has_store":false,"store_name":null,"followers":[],"creator":[],"created_at":"2022-11-04 15:15:56","updated_at":"2022-12-16 09:53:29","deleted_at":null,"roles":{"admin":true,"cliente":false,"profissional":false,"empresa":false}},"meta":{"messages":[{"channels":[],"title":"Aten\u00e7\u00e3o!","body":"","class":"info","type":"popup","id":"Voc\u00ea est\u00e1 no ambiente homolog","extra":[]}]}}}

            dispatch(login(response.data, response.data.token));
            history.push('/dashboard/')
            setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT); */

            //navigate('/', { replace: true })


        } catch (err) {
            console.log(err)
        }

        setLoading(false);
    }

    React.useEffect(() => {
        setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    }, []);

    const validationSchema = yup.object({
        email: yup
            .string('Insira seu e-mail')
            .email('Enter a valid email')
            .required('Email é obrigatório'),
        password: yup
            .string('Senha é obrigatória')
            .required('Senha é obrigatória'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleAuth(values);
        },
    });





    return (

        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/widgets/coffe.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#0E8EB5', .80)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%'
                        }}
                    >
                        <Div sx={{ mt: 'auto' }}>
                            <Link href="#" underline="none" sx={{ display: 'inline-flex' }}>
                                <img style={{ width: 120 }} src={`${ASSET_IMAGES}/logo-mifix-white.png`} alt="Jumbo React" />
                            </Link>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{ flex: 1, p: 4 }}
                >


                    <form onSubmit={formik.handleSubmit}>

                        <Div sx={{ mt: 1, mb: 3 }}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                defaultValue="admin@mifix.com.br"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Div>
                        <Div sx={{ mt: 1, mb: 2 }}>
                            <TextField
                                fullWidth
                                id="password"
                                label="Senha"
                                name="password"
                                type="password"
                                defaultValue="123456"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}

                            />
                        </Div>
                        <Div sx={{ mb: 2 }}>
                            <FormControlLabel control={<Checkbox />} label="Lembrar" />
                        </Div>
                        
                        <Stack>
                            <Button style={{ backgroundColor: "#0267a0" }} type="submit" variant="contained" sx={{ mb: 2 }}>Logar</Button>
                            <Link
                                onClick={() => history.push("recupera-senha")}
                                sx={{
                                    textAlign: "end",
                                    cursor: "pointer"
                                }}
                            >
                                Recuperar Senha
                            </Link>
                        </Stack>
                    </form>
                </CardContent>
            </Card>
        </Div>
    );
};

export default Login2;
