import React from 'react';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import Div from "@jumbo/shared/Div";

const ChartSalesOverview = ({data}) => {

    const capitalize = (val) => {
        let t = val == "resolvidosDireto" ? "resolvidos direto" : val;
        return `${t.charAt(0).toUpperCase()}${t.slice(1)}`;
    }

    return (
        <ResponsiveContainer height={192}>
            <AreaChart data={data} margin={{top: 0, right: 10, left: 10, bottom: 0}}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="1%" stopColor="#EAE7FF"/>
                        <stop offset="99%" stopColor="#ffffff"/>
                    </linearGradient>
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="3%" stopColor="#FFF2E0"/>
                        <stop offset="97%" stopColor="#ffffff"/>
                    </linearGradient>
                </defs>
                <Tooltip
                    animationEasing={"ease-in-out"}
                    content={({active, label, payload}) => {
                        return active ? (
                            <Div sx={{color: "common.white"}}>
                                {payload.map((row, index) => {
                                    return (
                                        <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                            <div style={{
                                                color: row.color,
                                                fontSize: 8,
                                                letterSpacing: 2,
                                                textTransform: 'uppercase'
                                            }}>
                                                {capitalize(row.name)}
                                            </div>
                                            <div style={{
                                                color: row.color
                                            }}
                                            >
                                                {row.value}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: 'rgba(255,255,255,0.8)',
                        borderRadius: 4,
                        padding: '5px 8px',
                        fontWeight: 500,
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                    cursor={false}
                />
                <CartesianGrid strokeDasharray="6 1 2" horizontal={false} strokeOpacity={0.2} stroke={"#E73145"}/>
                <Area
                    type="monotone"
                    dataKey={"abertos"}
                    stackId="2"
                    strokeWidth={2}
                    fillOpacity={.7}
                    stroke={"#7352C7"}
                    fill="url(#colorUv)"
                />
                <Area
                    type="monotone"
                    dataKey={"resolvidos"}
                    stackId="1"
                    strokeWidth={2}
                    fillOpacity={.7}
                    stroke={"#3BD2A2"}
                    fill="url(#colorUv1)"
                />
                <Area
                    type="monotone"
                    dataKey={"resolvidosDireto"}
                    stackId="1"
                    strokeWidth={2}
                    fillOpacity={.7}
                    stroke={"#7f8c8d"}
                    fill="url(#colorUv)"
                />
                <Area
                    type="monotone"
                    dataKey={"orfaos"}
                    stackId="4"
                    strokeWidth={2}
                    fillOpacity={.7}
                    stroke={"#F39711"}
                    fill="url(#colorUv1)"
                />
                <Area
                    type="monotone"
                    dataKey={"cancelados"}
                    stackId="3"
                    strokeWidth={2}
                    fillOpacity={.7}
                    stroke={"#E73145"}
                    fill="url(#colorUv)"
                />

                <XAxis dataKey="hKey" />
            </AreaChart>
        </ResponsiveContainer>
    );
};
/* Todo data prop define */
export default ChartSalesOverview;
