import axios from "./config";
import {conversations, favorite_users, users} from "./mock-data/chats";
import {getCustomDate, getCustomTime, idGenerator} from "@jumbo/utils";

const chatService = {};

chatService.getContacts = async () => {
    return {
        contacts: users.filter(user => user.id !== 1),
    };
};

chatService.getFavoriteConversations = async () => {
    const authUserConversations = conversations.filter(
        conversation => {
            if (conversation.first_user_id === 1) {
                return favorite_users.includes(conversation?.second_user_id);
            } else if (conversation.second_user_id === 1) {
                return favorite_users.includes(conversation?.first_user_id);
            }
            return false;
        }
    );
    const chatConversations = authUserConversations.map(conversation => {
        const userIndex = users.findIndex(user => {
            if (conversation.first_user_id === 1)
                return (user.id === conversation.second_user_id);

            return user.id === conversation.first_user_id;
        });
        conversation.contact = users[userIndex];
        conversation.last_message = conversation?.messages?.length > 0
            ? conversation.messages[conversation?.messages.length - 1]
            : "";
        return conversation;
    });

    return {
        conversations: chatConversations,
    };
};

chatService.getRecentConversations = async () => {
    const authUserConversations = conversations.filter(conversation =>
        (conversation.first_user_id === 1 || conversation.second_user_id === 1)
    );
    const chatConversations = authUserConversations.map(conversation => {
        const userIndex = users.findIndex(user => {
            if (conversation.first_user_id === 1) {
                return user.id === conversation.second_user_id;
            }
            return user.id === conversation.first_user_id;
        });
        conversation.contact = users[userIndex];
        conversation.last_message = conversation?.messages?.length > 0
            ? conversation.messages[conversation?.messages.length - 1]
            : "";
        return conversation;
    });

    return {
        conversations: chatConversations,
    };
};

chatService.getConversation = async ({queryKey}) => {
    const {id} = queryKey[queryKey.length - 1];
    if (id) {
        const conversationIndex = conversations.findIndex(conversation =>
            conversation.id === parseInt(id)
        );
        const conversation = conversations[conversationIndex];
        return {
            conversation: conversation,
        };
    }

    return {
        messages: []
    };
};

chatService.addConversationMessage = async (conversationDetail) => {
    console.log("CONVERSATION DETAIL > > ", conversationDetail);

    const conversationDetails = conversationDetail;
    if (conversationDetails.conversationID) {
        const conversationIndex = conversations.findIndex(conversation =>
            conversation.id === parseInt(conversationDetails.conversationID)
        );
        const conversation = conversations[conversationIndex];
        const conversationMessage = conversation.messages;
        const newMessage = {
            id: idGenerator(),
            message: conversationDetails.message,
            sent_by: 1,
            sent_at: getCustomTime(0, 'minutes'),
            sent_date: getCustomDate(0, "days"),
            unread: false,
        };
        conversationMessage.push(newMessage);
        return {
            newMessage: newMessage,
            conversations: conversationMessage,
            conversationMessage: conversation
        };
    } else {
        return {
            newMessage: {}
        };
    }
};

chatService.getConversationByContactID = async ({queryKey}) => {
    const {id} = queryKey[queryKey.length - 1];
    
    if (id) {
        const conversationIndex = conversations.findIndex(conversation => {
            return (
                (conversation.first_user_id === parseInt(id) && conversation.second_user_id === 1) ||
                (conversation.second_user_id === parseInt(id) && conversation.first_user_id === 1)
            )
        });
        let conversation = {};
        if (conversationIndex === -1) {
            conversation = {
                id: idGenerator(),
                first_user_id: parseInt(id),
                second_user_id: 1,
                contact: users.find(({id}) => id === parseInt(id)),
                messages: []
            };
            conversations.push(conversation);
        } else {
            conversation = conversations[conversationIndex];
        }
        conversation.last_message = conversation?.messages?.length > 0
            ? conversation.messages[conversation?.messages.length - 1]
            : "";
        return {
            conversation: conversation,
        };
    }
    return {conversation: null};
};

export {chatService};
