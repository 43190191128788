import React from 'react';
import ActivitiesList from "./ActivitiesList";
import Lottie from "lottie-react";
import emptyAnimation from "../../../assets/emptyAtividades.json";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { Stack } from '@mui/material';

const RecentActivities = ({scrollHeight, data}) => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick
            title={t('Últimas Atividades')}
            /* action={
                <Chip color={"warning"} size={"small"} label={"Últimos 30 dias"}/>
            } */
            wrapperSx={{px: 0}}
        >
            {
                data.length > 0 ? (
                    <JumboScrollbar
                        autoHeight
                        autoHeightMin={scrollHeight ? scrollHeight : 278}
                        autoHide
                        autoHideDuration={200}
                        autoHideTimeout={500}
                    >
                        <ActivitiesList data={data} />
                    </JumboScrollbar>
                ) : (
                    <Stack>
                        <Lottie
                            animationData={emptyAnimation}
                            style={{
                                height: 278,
                                margin: 0
                            }}
                        />
                    </Stack>
                )
            }
        </JumboCardQuick>
    );
};
/* Todo scrollHeight prop define */
export default RecentActivities;
