import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import { Stack } from "@mui/system";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import api from "app/services/config";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const ModalRelatorio = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);
  const { auth } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);

  const [paramsFilter, setParams] = useState({
    inicio: moment().subtract(1, "month").format("YYYY-MM-DD"),
    final: moment().format("YYYY-MM-DD"),
    status: 0,
  });

  async function handleExportExcel() {
    setLoading(true);

    try {
      const params = {};

      if (paramsFilter.inicio && paramsFilter.final) {
        params["period"] = `${paramsFilter.inicio},${paramsFilter.final}`;
      }

      if (paramsFilter.status) {
        params["status"] = paramsFilter.status;
      }

      const { data } = await api.get("api/v2/dashboard/tickets/export/period", {
        params,
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        responseType: "blob",
      });

      const blob = new Blob([data], { type: "application/vnd.ms-excel" });

      const el = document.getElementById("excelfileperiod");
      el.href = URL.createObjectURL(blob);
      el.click();
    } catch (error) {
      console.log("ERRO EXPORT EXCEL > > ", error);
    }
    setOpen(false);
    setLoading(false);
  }

  return (
    <Div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <a
        download={`TicketsCompleto-revisado-${paramsFilter.inicio}_${paramsFilter.final}.xlsx`}
        style={{ display: "none" }}
        id="excelfileperiod"
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Fade in={open}>
            <Div sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h2"
              >
                Relatório de Chamados Técnicos
              </Typography>

              <Stack direction="row" mt={5}>
                <FormControl sx={{ mr: 2, display: "flex", flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      fullWidth
                      label="Início"
                      format="DD/MM/YYYY"
                      value={moment(paramsFilter.inicio)}
                      maxDate={moment(paramsFilter.final)}
                      onChange={(date) =>
                        setParams({
                          ...paramsFilter,
                          inicio: moment(date).format("YYYY-MM-DD"),
                        })
                      }
                      slotProps={{
                        textField: {
                          InputProps: {
                            style: {
                              color: "#000",
                            },
                          },
                          InputLabelProps: {
                            style: {
                              color: "#8595A6",
                            },
                          },
                        },
                        desktopPaper: {
                          style: {
                            backgroundColor: "#000",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ mr: 2, display: "flex", flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      fullWidth
                      label="Início"
                      format="DD/MM/YYYY"
                      value={moment(paramsFilter.final)}
                      onChange={(date) =>
                        setParams({
                          ...paramsFilter,
                          final: moment(date).format("YYYY-MM-DD"),
                        })
                      }
                      maxDate={moment()}
                      slotProps={{
                        textField: {
                          InputProps: {
                            style: {
                              color: "#000",
                            },
                          },
                          InputLabelProps: {
                            style: {
                              color: "#8595A6",
                            },
                          },
                        },
                        desktopPaper: {
                          style: {
                            backgroundColor: "#000",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: "flex", flex: 1 }}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Status"
                    value={paramsFilter.status}
                    onChange={(event) =>
                      setParams({ ...paramsFilter, status: event.target.value })
                    }
                    InputLabelProps={{
                      style: {
                        color: "#8595A6",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#000",
                      },
                    }}
                  >
                    <MenuItem style={{ color: "#000" }} value={0}>
                      Todos
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={1}>
                      Aberto
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={2}>
                      Técnico à caminho
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={3}>
                      Técnico chegou
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={4}>
                      Resolvido
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={5}>
                      Pendente
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={6}>
                      Orfão
                    </MenuItem>
                    <MenuItem style={{ color: "#000" }} value={7}>
                      Cancelado
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Stack>

              <Button
                variant="contained"
                color="success"
                sx={{
                  mt: 5,
                  width: "100%",
                }}
                onClick={handleExportExcel}
              >
                Exportar Relatório
              </Button>
            </Div>
          </Fade>
        )}
      </Modal>
    </Div>
  );
};
export default ModalRelatorio;
