import React from 'react';
import Div from "@jumbo/shared/Div";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import useChatApp from "../../../hooks/useChatApp";
import { FileOpen } from '@mui/icons-material';

const ReceivedMessageContent = ({message}) => {
    const {activeConversation} = useChatApp();

    return (
        <Div
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                px: 3,
                '& .Message-item': {
                    marginTop: '2px'
                }
            }}
        >
            <React.Fragment>
                <Avatar
                    alt={activeConversation.name}
                    src={`https://storage.googleapis.com/uteky/images/tecnicos/${activeConversation.id}_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
                    sx={{width: 40, height: 40, mr: 2}}
                />
                <div className="Message-root">
                    <Typography
                        variant={"body1"}
                        color={"text.secondary"}
                        fontSize={"smaller"}
                        mb={.5}
                    >
                        {activeConversation?.name} - {message?.sent_at}
                    </Typography>
                    <div className="Message-item">
                        <Paper
                            elevation={0}
                            sx={{p: theme => theme.spacing(1.5, 2), bgcolor: 'primary.main', color: 'common.white'}}
                        >
                            {
                                message.content_type == "text" && <Typography variant={"body1"}>{message?.message}</Typography>
                            }

                            {
                                message.content_type == "imgfile" && (
                                    <a href={`https://storage.googleapis.com/uteky/chat/${message?.message}`} target="_blank">
                                        <img style={{ width: 500, height: 500, borderRadius: 10 }} src={`https://storage.googleapis.com/uteky/chat/${message?.message}`} />
                                    </a>
                                )
                            }

                            {
                                message.content_type == "video/mp4" && (
                                    <video
                                        style={{ width: 500, height: 500, borderRadius: 10 }}
                                        src={`https://storage.googleapis.com/uteky/chat/${message?.message}`}
                                        controls
                                    />
                                )
                            }

                            {
                                message.content_type == "textfile" && (
                                    <a
                                        href={`https://storage.googleapis.com/uteky/chat/${message?.message}`}
                                        target="_blank"
                                        style={{
                                            color: "#FFF",
                                        }}
                                    >
                                        <FileOpen color='#FFF' style={{ width: 70, height: 70 }} />
                                    </a>
                                )
                            }
                        </Paper>
                    </div>
                </div>
            </React.Fragment>
        </Div>
    );
};

export default ReceivedMessageContent;
