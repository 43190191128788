import React from "react";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const menus = [
  {
    label: "Chamados",
    type: "section",
    children: [
      {
        label: "Chamados Técnicos",
        type: "collapsible",
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/chamados/todos",
            label: "Todos",
            type: "nav-item",
          },
          {
            uri: "/chamados/abertos",
            label: "Abertos",
            type: "nav-item",
          },
          {
            uri: "/chamados/tecnico_a_caminho",
            label: "Técnico a caminho",
            type: "nav-item",
          },
          {
            uri: "/chamados/tecnico_chegou",
            label: "Técnico chegou",
            type: "nav-item",
          },
          {
            uri: "/chamados/resolvidos",
            label: "Resolvidos",
            type: "nav-item",
          },
          {
            uri: "/chamados/pendentes",
            label: "Pendentes",
            type: "nav-item",
          },
          {
            uri: "/chamados/orfao",
            label: "Orfão",
            type: "nav-item",
          },
          {
            uri: "/chamados/cancelados",
            label: "Cancelados",
            type: "nav-item",
          },
        ],
      },
      {
        uri: "/tecnicos/fila",
        label: "Filas dos técnicos",
        type: "nav-item",
        icon: <QueueOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      /* {
                uri: "/qrcode",
                label: "Gerador de QRCODE",
                type: "nav-item",
                icon: <QrCodeOutlinedIcon sx={{ fontSize: 20 }} />
            }, */
    ],
  },
  {
    label: "Relatórios",
    type: "section",
    children: [
      {
        uri: "/relatorios",
        label: "Baixar Excel",
        excel: "report",
        type: "nav-item",
        icon: <CloudDownloadOutlinedIcon sx={{ fontSize: 20 }} />,
        openModal: true,
      },
      {
        uri: "/pagamentos",
        label: "Pagamentos",
        excel: "payment",
        type: "nav-item",
        icon: <CloudDownloadOutlinedIcon sx={{ fontSize: 20 }} />,
        openModal: true,
      },
      {
        uri: "/tecnicos/localizacao",
        label: "Localização",
        icon: <LocationOnIcon sx={{ fontSize: 20 }} />,
        type: "nav-item",
      },
    ],
  },

  {
    label: "Configurações",
    type: "section",
    children: [
      {
        label: "Usuários",
        type: "collapsible",
        icon: <BadgeOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/usuarios/clientes",
            label: "Clientes",
            type: "nav-item",
          },
          {
            uri: "/usuarios/tecnicos",
            label: "Técnicos",
            type: "nav-item",
          },
          /* {
                        uri: "/usuarios/sac",
                        label: "Sac",
                        type: "nav-item"
                    }, */
          {
            uri: "/usuarios/administradores",
            label: "Administradores",
            type: "nav-item",
          },
        ],
      },
      /* {
                uri: "/historicos",
                label: "Historico",
                type: "nav-item",
                icon: <HistoryOutlinedIcon sx={{ fontSize: 20 }} />
            },  */
      {
        uri: "/chats",
        label: "Chat",
        type: "nav-item",
        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/configuracao",
        label: "Configuração",
        type: "nav-item",
        icon: <MiscellaneousServicesOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
