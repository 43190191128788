import React, { useState } from 'react';
import ProjectItem from "./ProjectItem";
import {
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import { Chip } from "@mui/material";
import { history } from 'app/redux/store';
import JumboSearch from '@jumbo/components/JumboSearch';
import Div from '@jumbo/shared/Div';
import SortIcon from "@mui/icons-material/Sort";

const ProjectsList = ({ data }) => {
    return (
        <React.Fragment>
            {
                data.length > 0 ? (
                    <>
                        {
                            data.map((project, key) => (
                                <ProjectItem data={data} item={project}/>
                            ))
                        }
                    </>
                ) : (
                    <Typography variant={'h4'} mb={3}>Nenhum chamado para listar</Typography>
                )
            }
        </React.Fragment>
    );
};

export default ProjectsList;
