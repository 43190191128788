const INITIAL_STATE = {
  lastSendNotification: null,
  openRelatorio: false,
  excel: null,
};

export default function settings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@mybway_lastSend":
      return {
        ...state,
        lastSendNotification: new Date(),
      };
    case "@mybway_relatorio":
      return {
        ...state,
        openRelatorio: action.payload.value,
        excel: action.payload.excel,
      };
    default:
      return state;
  }
}
