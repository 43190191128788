import React from "react";
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RevenueChart from "../RevenueThisYear/RevenueChart";

const ChamadosResolvidosDiretos = ({ value, data }) => {
  const { t } = useTranslation();
  return (
    <JumboCardQuick
      title={
        <Typography fontWeight={"500"} variant={"h3"} color={"common.white"}>
          {value || 0}
        </Typography>
      }
      subheader={
        <Typography variant={"h6"} color={"common.white"} mb={0}>
          {t("Em andamento")}
        </Typography>
      }
      reverse
      sx={{ color: "common.white", borderTop: "4px solid #7f8c8d" }}
      bgColor={["#bdc3c7", "#7f8c8d"]}
      wrapperSx={{ pb: 0 }}
    >
      {console.log("Em andamento", data)}
      <RevenueChart data={data} dataKey="emAndamento" shadowColor="#95a5a6" />
    </JumboCardQuick>
  );
};

export default ChamadosResolvidosDiretos;
