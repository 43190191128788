import React, { useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import {Stack, Typography} from "@mui/material";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import useChatApp from "../../hooks/useChatApp";
import ConversationItem from "../ConversationItem";
import {chatService} from "../../../../services/chat-services";
import { socket } from 'app/services/socket';
import noUsersAnimation from "../../../../assets/no-connection.json";
import Lottie from "lottie-react";

const RecentConversationsList = () => {
    const {recentConversationsListRef} = useChatApp();

    const [chats, setChats] = useState([]);

    const renderContact = React.useCallback((contact) => {
        return (
            <ConversationItem conversationItem={contact}/>
        );
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            socket.on('receiveConnectedUser', (user) => {
                if(user && user.type == "tecnico") {
                    let inList = false;
    
                    chats.map(chat => {
                        if(chat.id == user.id) inList = true;
                    })
    
                    if(!inList)
                        setChats(prev => [...prev, user]);
                }
            });
    
            socket.on('receiveConnectedUsers', (users) => {
                setChats(users);
                socket.emit('allUnReadMessage', {})
            });
    
            socket.on('userDisconnected', user => {
                if(user) {
                    setChats(prev => prev.filter(u => u.id != user.id));
                }
            })
    
            socket.on('unreadMessages', (data) =>{
            })
    
            socket.emit('connectedUsers', {});
        }, 1500);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <React.Fragment>
            {/* <Div
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: theme => theme.spacing(1.5, 2),
                    bgcolor: theme => theme.palette.action.hover,
                }}
            >
                <Typography
                    sx={{letterSpacing: 1.5, textTransform: 'uppercase'}}
                    variant="h6"
                    color="text.secondary" mb={0}
                >
                    <small>Recent chats</small>
                </Typography>
            </Div> */}

            <Stack>
                {
                    chats.length > 0 ? (
                        <>
                            {
                                chats.map(renderContact)
                            }
                        </>
                    ) : <Lottie animationData={noUsersAnimation} height={350} />
                }
            </Stack>
            
            {/* <JumboRqList
                ref={recentConversationsListRef}
                service={chatService.getRecentConversations}
                renderItem={renderContact}
                primaryKey={"id"}
                queryOptions={{
                    queryKey: "recent-conversations-list",
                    dataKey: "conversations"
                }}
                componentElement={"div"}
            /> */}
        </React.Fragment>

    );
};

export default RecentConversationsList;
